import { FaucetNft } from "../../types/faucetNft";
import { graph, httpClient } from "./apiClient";

const getFaucetNfts = async (): Promise<FaucetNft[]> => {
	const QUERY = httpClient.query({
		faucetNft: {
			metadata: {
				uri: true,
				name: true,
				tokens: [
					{
						limit: 1,

						where: {
							_or: [
								{ units: { wave: { _neq: 0 } } },
								{ category: { _neq: "unit" } },
							],
						},
					},
					{
						category: true,
						artifacts: {
							uno: true,
							min: true,
							enr: true,
							mch: true,
						},
						collectibles: {
							level: true,
						},
						tezotops: {
							uno: true,
							min: true,
							enr: true,
							mch: true,
						},
						units: [
							{ order_by: [{ wave: "desc" }] },
							{
								accuracy: true,
								attack: true,
								defence: true,
								exp: true,
								hp: true,
								wave: true,
								type: true,
							},
						],
					},
				],
				thumbnail: true,
			},
		},
	});
	const data = await graph(QUERY);
	return data.faucetNft;
};
export default getFaucetNfts;
