import CONFIG from "../../config/config";
import { createClient } from "./genql";

console.log("created http API client");
export const httpClient = createClient({
	url: String(CONFIG.GRAPHQL_ENDPOINT),
});

console.log("created ws API client");
export const wsClient = createClient({
	url: (CONFIG.GRAPHQL_ENDPOINT as string).replace(/http[s]*:\/\//, "wss://"),
});

/**
 * GraphQL request Gateway
 * @param {Promise} request - the request to be sent -- httpClient.query() instance
 * @param  {...Function} middlewares - the middlewares to be applied to the request data
 * @returns data - the data returned from the request and middlewares
 * @throws error - the error returned from the request and middlewares
 */
export const graph = async (request: any, ...middlewares: any) => {
	try {
		let data = await request;
		for (const middleware of middlewares) {
			data = middleware(data);
		}
		return data;
	} catch (err) {
		console.log("GRAPH ERROR - ");
		throw err;
	}
};
