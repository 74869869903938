export default {
    "scalars": [
        0,
        2,
        3,
        5,
        19,
        49,
        65,
        78,
        99,
        132,
        159,
        187,
        197,
        206,
        214,
        230,
        246,
        261,
        270,
        292,
        298,
        325,
        352,
        375,
        383,
        398,
        425,
        460,
        475,
        491,
        500,
        515,
        529,
        544,
        560,
        573,
        612,
        627,
        654,
        687,
        714,
        748,
        768,
        783,
        797,
        824,
        851,
        873,
        882
    ],
    "types": {
        "Boolean": {},
        "Boolean_comparison_exp": {
            "_eq": [
                0
            ],
            "_gt": [
                0
            ],
            "_gte": [
                0
            ],
            "_in": [
                0
            ],
            "_is_null": [
                0
            ],
            "_lt": [
                0
            ],
            "_lte": [
                0
            ],
            "_neq": [
                0
            ],
            "_nin": [
                0
            ],
            "__typename": [
                5
            ]
        },
        "Float": {},
        "Int": {},
        "Int_comparison_exp": {
            "_eq": [
                3
            ],
            "_gt": [
                3
            ],
            "_gte": [
                3
            ],
            "_in": [
                3
            ],
            "_is_null": [
                0
            ],
            "_lt": [
                3
            ],
            "_lte": [
                3
            ],
            "_neq": [
                3
            ],
            "_nin": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "String": {},
        "String_comparison_exp": {
            "_eq": [
                5
            ],
            "_gt": [
                5
            ],
            "_gte": [
                5
            ],
            "_ilike": [
                5
            ],
            "_in": [
                5
            ],
            "_iregex": [
                5
            ],
            "_is_null": [
                0
            ],
            "_like": [
                5
            ],
            "_lt": [
                5
            ],
            "_lte": [
                5
            ],
            "_neq": [
                5
            ],
            "_nilike": [
                5
            ],
            "_nin": [
                5
            ],
            "_niregex": [
                5
            ],
            "_nlike": [
                5
            ],
            "_nregex": [
                5
            ],
            "_nsimilar": [
                5
            ],
            "_regex": [
                5
            ],
            "_similar": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "artifact": {
            "enr": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "name": [
                5
            ],
            "stock": [
                0
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "artifact_aggregate": {
            "aggregate": [
                9
            ],
            "nodes": [
                7
            ],
            "__typename": [
                5
            ]
        },
        "artifact_aggregate_fields": {
            "avg": [
                11
            ],
            "count": [
                3,
                {
                    "columns": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                14
            ],
            "min": [
                16
            ],
            "stddev": [
                20
            ],
            "stddev_pop": [
                22
            ],
            "stddev_samp": [
                24
            ],
            "sum": [
                26
            ],
            "var_pop": [
                28
            ],
            "var_samp": [
                30
            ],
            "variance": [
                32
            ],
            "__typename": [
                5
            ]
        },
        "artifact_aggregate_order_by": {
            "avg": [
                12
            ],
            "count": [
                544
            ],
            "max": [
                15
            ],
            "min": [
                17
            ],
            "stddev": [
                21
            ],
            "stddev_pop": [
                23
            ],
            "stddev_samp": [
                25
            ],
            "sum": [
                27
            ],
            "var_pop": [
                29
            ],
            "var_samp": [
                31
            ],
            "variance": [
                33
            ],
            "__typename": [
                5
            ]
        },
        "artifact_avg_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_avg_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_bool_exp": {
            "_and": [
                13
            ],
            "_not": [
                13
            ],
            "_or": [
                13
            ],
            "enr": [
                4
            ],
            "mch": [
                4
            ],
            "min": [
                4
            ],
            "name": [
                6
            ],
            "stock": [
                1
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "uid": [
                4
            ],
            "uno": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "artifact_max_fields": {
            "enr": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "artifact_max_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_min_fields": {
            "enr": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "artifact_min_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "name": [
                544
            ],
            "stock": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_select_column": {},
        "artifact_stddev_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_stddev_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_stddev_pop_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_stddev_pop_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_stddev_samp_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_stddev_samp_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_sum_fields": {
            "enr": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "artifact_sum_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_var_pop_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_var_pop_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_var_samp_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_var_samp_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "artifact_variance_fields": {
            "enr": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "artifact_variance_order_by": {
            "enr": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle": {
            "active": [
                0
            ],
            "battleType": [
                3
            ],
            "battleUnits": [
                87,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "battleUnits_aggregate": [
                88,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "challengeAccepted": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "challengeAccepted_aggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "challengeCreated": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "challengeCreated_aggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "counter": [
                3
            ],
            "id": [
                3
            ],
            "lockTime": [
                783
            ],
            "tokens": [
                413,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "tokens_aggregate": [
                414,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "unlockTime": [
                783
            ],
            "user": [
                866
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "battle_aggregate": {
            "aggregate": [
                36
            ],
            "nodes": [
                34
            ],
            "__typename": [
                5
            ]
        },
        "battle_aggregate_fields": {
            "avg": [
                38
            ],
            "count": [
                3,
                {
                    "columns": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                73
            ],
            "min": [
                75
            ],
            "stddev": [
                79
            ],
            "stddev_pop": [
                81
            ],
            "stddev_samp": [
                83
            ],
            "sum": [
                85
            ],
            "var_pop": [
                114
            ],
            "var_samp": [
                116
            ],
            "variance": [
                118
            ],
            "__typename": [
                5
            ]
        },
        "battle_aggregate_order_by": {
            "avg": [
                39
            ],
            "count": [
                544
            ],
            "max": [
                74
            ],
            "min": [
                76
            ],
            "stddev": [
                80
            ],
            "stddev_pop": [
                82
            ],
            "stddev_samp": [
                84
            ],
            "sum": [
                86
            ],
            "var_pop": [
                115
            ],
            "var_samp": [
                117
            ],
            "variance": [
                119
            ],
            "__typename": [
                5
            ]
        },
        "battle_avg_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_avg_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_bool_exp": {
            "_and": [
                40
            ],
            "_not": [
                40
            ],
            "_or": [
                40
            ],
            "active": [
                1
            ],
            "battleType": [
                4
            ],
            "battleUnits": [
                93
            ],
            "challengeAccepted": [
                319
            ],
            "challengeCreated": [
                319
            ],
            "counter": [
                4
            ],
            "id": [
                4
            ],
            "lockTime": [
                784
            ],
            "tokens": [
                419
            ],
            "unlockTime": [
                784
            ],
            "user": [
                869
            ],
            "userId": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter": {
            "address": [
                5
            ],
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_aggregate": {
            "aggregate": [
                43
            ],
            "nodes": [
                41
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_aggregate_fields": {
            "avg": [
                44
            ],
            "count": [
                3,
                {
                    "columns": [
                        49,
                        "[battle_counter_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                46
            ],
            "min": [
                47
            ],
            "stddev": [
                50
            ],
            "stddev_pop": [
                51
            ],
            "stddev_samp": [
                52
            ],
            "sum": [
                53
            ],
            "var_pop": [
                54
            ],
            "var_samp": [
                55
            ],
            "variance": [
                56
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_avg_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_bool_exp": {
            "_and": [
                45
            ],
            "_not": [
                45
            ],
            "_or": [
                45
            ],
            "address": [
                6
            ],
            "counter": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_max_fields": {
            "address": [
                5
            ],
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_min_fields": {
            "address": [
                5
            ],
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_order_by": {
            "address": [
                544
            ],
            "counter": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_select_column": {},
        "battle_counter_stddev_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_stddev_pop_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_stddev_samp_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_sum_fields": {
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_var_pop_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_var_samp_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_counter_variance_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate": {
            "address": [
                5
            ],
            "amount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_aggregate": {
            "aggregate": [
                59
            ],
            "nodes": [
                57
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_aggregate_fields": {
            "avg": [
                60
            ],
            "count": [
                3,
                {
                    "columns": [
                        65,
                        "[battle_crate_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                62
            ],
            "min": [
                63
            ],
            "stddev": [
                66
            ],
            "stddev_pop": [
                67
            ],
            "stddev_samp": [
                68
            ],
            "sum": [
                69
            ],
            "var_pop": [
                70
            ],
            "var_samp": [
                71
            ],
            "variance": [
                72
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_avg_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_bool_exp": {
            "_and": [
                61
            ],
            "_not": [
                61
            ],
            "_or": [
                61
            ],
            "address": [
                6
            ],
            "amount": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_max_fields": {
            "address": [
                5
            ],
            "amount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_min_fields": {
            "address": [
                5
            ],
            "amount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_order_by": {
            "address": [
                544
            ],
            "amount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_select_column": {},
        "battle_crate_stddev_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_stddev_pop_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_stddev_samp_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_sum_fields": {
            "amount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_var_pop_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_var_samp_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_crate_variance_fields": {
            "amount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_max_fields": {
            "battleType": [
                3
            ],
            "counter": [
                3
            ],
            "id": [
                3
            ],
            "lockTime": [
                783
            ],
            "unlockTime": [
                783
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "battle_max_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "lockTime": [
                544
            ],
            "unlockTime": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_min_fields": {
            "battleType": [
                3
            ],
            "counter": [
                3
            ],
            "id": [
                3
            ],
            "lockTime": [
                783
            ],
            "unlockTime": [
                783
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "battle_min_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "lockTime": [
                544
            ],
            "unlockTime": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_order_by": {
            "active": [
                544
            ],
            "battleType": [
                544
            ],
            "battleUnits_aggregate": [
                90
            ],
            "challengeAccepted_aggregate": [
                316
            ],
            "challengeCreated_aggregate": [
                316
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "lockTime": [
                544
            ],
            "tokens_aggregate": [
                416
            ],
            "unlockTime": [
                544
            ],
            "user": [
                872
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_select_column": {},
        "battle_stddev_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_stddev_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_stddev_pop_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_stddev_pop_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_stddev_samp_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_stddev_samp_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_sum_fields": {
            "battleType": [
                3
            ],
            "counter": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_sum_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit": {
            "battle": [
                34
            ],
            "battleId": [
                3
            ],
            "unit": [
                839
            ],
            "unitId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_aggregate": {
            "aggregate": [
                89
            ],
            "nodes": [
                87
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_aggregate_fields": {
            "avg": [
                91
            ],
            "count": [
                3,
                {
                    "columns": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                94
            ],
            "min": [
                96
            ],
            "stddev": [
                100
            ],
            "stddev_pop": [
                102
            ],
            "stddev_samp": [
                104
            ],
            "sum": [
                106
            ],
            "var_pop": [
                108
            ],
            "var_samp": [
                110
            ],
            "variance": [
                112
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_aggregate_order_by": {
            "avg": [
                92
            ],
            "count": [
                544
            ],
            "max": [
                95
            ],
            "min": [
                97
            ],
            "stddev": [
                101
            ],
            "stddev_pop": [
                103
            ],
            "stddev_samp": [
                105
            ],
            "sum": [
                107
            ],
            "var_pop": [
                109
            ],
            "var_samp": [
                111
            ],
            "variance": [
                113
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_avg_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_avg_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_bool_exp": {
            "_and": [
                93
            ],
            "_not": [
                93
            ],
            "_or": [
                93
            ],
            "battle": [
                40
            ],
            "battleId": [
                4
            ],
            "unit": [
                845
            ],
            "unitId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_max_fields": {
            "battleId": [
                3
            ],
            "unitId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_max_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_min_fields": {
            "battleId": [
                3
            ],
            "unitId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_min_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_order_by": {
            "battle": [
                77
            ],
            "battleId": [
                544
            ],
            "unit": [
                850
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_select_column": {},
        "battle_unit_stddev_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_stddev_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_stddev_pop_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_stddev_pop_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_stddev_samp_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_stddev_samp_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_sum_fields": {
            "battleId": [
                3
            ],
            "unitId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_sum_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_var_pop_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_var_pop_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_var_samp_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_var_samp_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_variance_fields": {
            "battleId": [
                2
            ],
            "unitId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_unit_variance_order_by": {
            "battleId": [
                544
            ],
            "unitId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_var_pop_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_var_pop_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_var_samp_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_var_samp_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "battle_variance_fields": {
            "battleType": [
                2
            ],
            "counter": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "battle_variance_order_by": {
            "battleType": [
                544
            ],
            "counter": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building": {
            "active": [
                0
            ],
            "boosted": [
                0
            ],
            "buildTime": [
                783
            ],
            "id": [
                3
            ],
            "symbol": [
                5
            ],
            "tezotop": [
                702
            ],
            "tezotopId": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "building_aggregate": {
            "aggregate": [
                122
            ],
            "nodes": [
                120
            ],
            "__typename": [
                5
            ]
        },
        "building_aggregate_fields": {
            "avg": [
                124
            ],
            "count": [
                3,
                {
                    "columns": [
                        132,
                        "[building_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                127
            ],
            "min": [
                129
            ],
            "stddev": [
                133
            ],
            "stddev_pop": [
                135
            ],
            "stddev_samp": [
                137
            ],
            "sum": [
                139
            ],
            "var_pop": [
                141
            ],
            "var_samp": [
                143
            ],
            "variance": [
                145
            ],
            "__typename": [
                5
            ]
        },
        "building_aggregate_order_by": {
            "avg": [
                125
            ],
            "count": [
                544
            ],
            "max": [
                128
            ],
            "min": [
                130
            ],
            "stddev": [
                134
            ],
            "stddev_pop": [
                136
            ],
            "stddev_samp": [
                138
            ],
            "sum": [
                140
            ],
            "var_pop": [
                142
            ],
            "var_samp": [
                144
            ],
            "variance": [
                146
            ],
            "__typename": [
                5
            ]
        },
        "building_avg_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_avg_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_bool_exp": {
            "_and": [
                126
            ],
            "_not": [
                126
            ],
            "_or": [
                126
            ],
            "active": [
                1
            ],
            "boosted": [
                1
            ],
            "buildTime": [
                784
            ],
            "id": [
                4
            ],
            "symbol": [
                6
            ],
            "tezotop": [
                708
            ],
            "tezotopId": [
                4
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "building_max_fields": {
            "buildTime": [
                783
            ],
            "id": [
                3
            ],
            "symbol": [
                5
            ],
            "tezotopId": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "building_max_order_by": {
            "buildTime": [
                544
            ],
            "id": [
                544
            ],
            "symbol": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_min_fields": {
            "buildTime": [
                783
            ],
            "id": [
                3
            ],
            "symbol": [
                5
            ],
            "tezotopId": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "building_min_order_by": {
            "buildTime": [
                544
            ],
            "id": [
                544
            ],
            "symbol": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_order_by": {
            "active": [
                544
            ],
            "boosted": [
                544
            ],
            "buildTime": [
                544
            ],
            "id": [
                544
            ],
            "symbol": [
                544
            ],
            "tezotop": [
                713
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_select_column": {},
        "building_stddev_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_stddev_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_stddev_pop_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_stddev_pop_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_stddev_samp_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_stddev_samp_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_sum_fields": {
            "id": [
                3
            ],
            "tezotopId": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "building_sum_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_var_pop_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_var_pop_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_var_samp_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_var_samp_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "building_variance_fields": {
            "id": [
                2
            ],
            "tezotopId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "building_variance_order_by": {
            "id": [
                544
            ],
            "tezotopId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible": {
            "level": [
                3
            ],
            "name": [
                5
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "collectible_aggregate": {
            "aggregate": [
                149
            ],
            "nodes": [
                147
            ],
            "__typename": [
                5
            ]
        },
        "collectible_aggregate_fields": {
            "avg": [
                151
            ],
            "count": [
                3,
                {
                    "columns": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                154
            ],
            "min": [
                156
            ],
            "stddev": [
                160
            ],
            "stddev_pop": [
                162
            ],
            "stddev_samp": [
                164
            ],
            "sum": [
                166
            ],
            "var_pop": [
                168
            ],
            "var_samp": [
                170
            ],
            "variance": [
                172
            ],
            "__typename": [
                5
            ]
        },
        "collectible_aggregate_order_by": {
            "avg": [
                152
            ],
            "count": [
                544
            ],
            "max": [
                155
            ],
            "min": [
                157
            ],
            "stddev": [
                161
            ],
            "stddev_pop": [
                163
            ],
            "stddev_samp": [
                165
            ],
            "sum": [
                167
            ],
            "var_pop": [
                169
            ],
            "var_samp": [
                171
            ],
            "variance": [
                173
            ],
            "__typename": [
                5
            ]
        },
        "collectible_avg_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_avg_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_bool_exp": {
            "_and": [
                153
            ],
            "_not": [
                153
            ],
            "_or": [
                153
            ],
            "level": [
                4
            ],
            "name": [
                6
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "uid": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "collectible_max_fields": {
            "level": [
                3
            ],
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "collectible_max_order_by": {
            "level": [
                544
            ],
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_min_fields": {
            "level": [
                3
            ],
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "collectible_min_order_by": {
            "level": [
                544
            ],
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_order_by": {
            "level": [
                544
            ],
            "name": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_select_column": {},
        "collectible_stddev_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_stddev_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_stddev_pop_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_stddev_pop_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_stddev_samp_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_stddev_samp_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_sum_fields": {
            "level": [
                3
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "collectible_sum_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_var_pop_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_var_pop_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_var_samp_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_var_samp_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "collectible_variance_fields": {
            "level": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "collectible_variance_order_by": {
            "level": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract": {
            "address": [
                5
            ],
            "createdAt": [
                783
            ],
            "name": [
                5
            ],
            "typename": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_aggregate": {
            "aggregate": [
                176
            ],
            "nodes": [
                174
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_aggregate_fields": {
            "count": [
                3,
                {
                    "columns": [
                        197,
                        "[dipdup_contract_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                178
            ],
            "min": [
                195
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_bool_exp": {
            "_and": [
                177
            ],
            "_not": [
                177
            ],
            "_or": [
                177
            ],
            "address": [
                6
            ],
            "createdAt": [
                784
            ],
            "name": [
                6
            ],
            "typename": [
                6
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_max_fields": {
            "address": [
                5
            ],
            "createdAt": [
                783
            ],
            "name": [
                5
            ],
            "typename": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata": {
            "contract": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "metadata": [
                383,
                {
                    "path": [
                        5
                    ]
                }
            ],
            "network": [
                5
            ],
            "updateId": [
                3
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_aggregate": {
            "aggregate": [
                181
            ],
            "nodes": [
                179
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_aggregate_fields": {
            "avg": [
                182
            ],
            "count": [
                3,
                {
                    "columns": [
                        187,
                        "[dipdup_contract_metadata_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                184
            ],
            "min": [
                185
            ],
            "stddev": [
                188
            ],
            "stddev_pop": [
                189
            ],
            "stddev_samp": [
                190
            ],
            "sum": [
                191
            ],
            "var_pop": [
                192
            ],
            "var_samp": [
                193
            ],
            "variance": [
                194
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_avg_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_bool_exp": {
            "_and": [
                183
            ],
            "_not": [
                183
            ],
            "_or": [
                183
            ],
            "contract": [
                6
            ],
            "createdAt": [
                784
            ],
            "id": [
                4
            ],
            "metadata": [
                385
            ],
            "network": [
                6
            ],
            "updateId": [
                4
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_max_fields": {
            "contract": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "network": [
                5
            ],
            "updateId": [
                3
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_min_fields": {
            "contract": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "network": [
                5
            ],
            "updateId": [
                3
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_order_by": {
            "contract": [
                544
            ],
            "createdAt": [
                544
            ],
            "id": [
                544
            ],
            "metadata": [
                544
            ],
            "network": [
                544
            ],
            "updateId": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_select_column": {},
        "dipdup_contract_metadata_stddev_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_stddev_pop_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_stddev_samp_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_sum_fields": {
            "id": [
                3
            ],
            "updateId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_var_pop_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_var_samp_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_metadata_variance_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_min_fields": {
            "address": [
                5
            ],
            "createdAt": [
                783
            ],
            "name": [
                5
            ],
            "typename": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_order_by": {
            "address": [
                544
            ],
            "createdAt": [
                544
            ],
            "name": [
                544
            ],
            "typename": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_contract_select_column": {},
        "dipdup_head": {
            "createdAt": [
                783
            ],
            "hash": [
                5
            ],
            "level": [
                3
            ],
            "name": [
                5
            ],
            "timestamp": [
                783
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_aggregate": {
            "aggregate": [
                200
            ],
            "nodes": [
                198
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_aggregate_fields": {
            "avg": [
                201
            ],
            "count": [
                3,
                {
                    "columns": [
                        206,
                        "[dipdup_head_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                203
            ],
            "min": [
                204
            ],
            "stddev": [
                215
            ],
            "stddev_pop": [
                216
            ],
            "stddev_samp": [
                217
            ],
            "sum": [
                218
            ],
            "var_pop": [
                219
            ],
            "var_samp": [
                220
            ],
            "variance": [
                221
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_avg_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_bool_exp": {
            "_and": [
                202
            ],
            "_not": [
                202
            ],
            "_or": [
                202
            ],
            "createdAt": [
                784
            ],
            "hash": [
                6
            ],
            "level": [
                4
            ],
            "name": [
                6
            ],
            "timestamp": [
                784
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_max_fields": {
            "createdAt": [
                783
            ],
            "hash": [
                5
            ],
            "level": [
                3
            ],
            "name": [
                5
            ],
            "timestamp": [
                783
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_min_fields": {
            "createdAt": [
                783
            ],
            "hash": [
                5
            ],
            "level": [
                3
            ],
            "name": [
                5
            ],
            "timestamp": [
                783
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_order_by": {
            "createdAt": [
                544
            ],
            "hash": [
                544
            ],
            "level": [
                544
            ],
            "name": [
                544
            ],
            "timestamp": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_select_column": {},
        "dipdup_head_status": {
            "name": [
                5
            ],
            "status": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_aggregate": {
            "aggregate": [
                209
            ],
            "nodes": [
                207
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_aggregate_fields": {
            "count": [
                3,
                {
                    "columns": [
                        214,
                        "[dipdup_head_status_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                211
            ],
            "min": [
                212
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_bool_exp": {
            "_and": [
                210
            ],
            "_not": [
                210
            ],
            "_or": [
                210
            ],
            "name": [
                6
            ],
            "status": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_max_fields": {
            "name": [
                5
            ],
            "status": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_min_fields": {
            "name": [
                5
            ],
            "status": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_order_by": {
            "name": [
                544
            ],
            "status": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_status_select_column": {},
        "dipdup_head_stddev_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_stddev_pop_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_stddev_samp_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_sum_fields": {
            "level": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_var_pop_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_var_samp_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_head_variance_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index": {
            "configHash": [
                5
            ],
            "createdAt": [
                783
            ],
            "level": [
                3
            ],
            "name": [
                5
            ],
            "status": [
                5
            ],
            "template": [
                5
            ],
            "templateValues": [
                383,
                {
                    "path": [
                        5
                    ]
                }
            ],
            "type": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_aggregate": {
            "aggregate": [
                224
            ],
            "nodes": [
                222
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_aggregate_fields": {
            "avg": [
                225
            ],
            "count": [
                3,
                {
                    "columns": [
                        230,
                        "[dipdup_index_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                227
            ],
            "min": [
                228
            ],
            "stddev": [
                231
            ],
            "stddev_pop": [
                232
            ],
            "stddev_samp": [
                233
            ],
            "sum": [
                234
            ],
            "var_pop": [
                235
            ],
            "var_samp": [
                236
            ],
            "variance": [
                237
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_avg_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_bool_exp": {
            "_and": [
                226
            ],
            "_not": [
                226
            ],
            "_or": [
                226
            ],
            "configHash": [
                6
            ],
            "createdAt": [
                784
            ],
            "level": [
                4
            ],
            "name": [
                6
            ],
            "status": [
                6
            ],
            "template": [
                6
            ],
            "templateValues": [
                385
            ],
            "type": [
                6
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_max_fields": {
            "configHash": [
                5
            ],
            "createdAt": [
                783
            ],
            "level": [
                3
            ],
            "name": [
                5
            ],
            "status": [
                5
            ],
            "template": [
                5
            ],
            "type": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_min_fields": {
            "configHash": [
                5
            ],
            "createdAt": [
                783
            ],
            "level": [
                3
            ],
            "name": [
                5
            ],
            "status": [
                5
            ],
            "template": [
                5
            ],
            "type": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_order_by": {
            "configHash": [
                544
            ],
            "createdAt": [
                544
            ],
            "level": [
                544
            ],
            "name": [
                544
            ],
            "status": [
                544
            ],
            "template": [
                544
            ],
            "templateValues": [
                544
            ],
            "type": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_select_column": {},
        "dipdup_index_stddev_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_stddev_pop_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_stddev_samp_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_sum_fields": {
            "level": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_var_pop_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_var_samp_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_index_variance_fields": {
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update": {
            "action": [
                5
            ],
            "createdAt": [
                783
            ],
            "data": [
                383,
                {
                    "path": [
                        5
                    ]
                }
            ],
            "id": [
                3
            ],
            "index": [
                5
            ],
            "level": [
                3
            ],
            "modelName": [
                5
            ],
            "modelPk": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_aggregate": {
            "aggregate": [
                240
            ],
            "nodes": [
                238
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_aggregate_fields": {
            "avg": [
                241
            ],
            "count": [
                3,
                {
                    "columns": [
                        246,
                        "[dipdup_model_update_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                243
            ],
            "min": [
                244
            ],
            "stddev": [
                247
            ],
            "stddev_pop": [
                248
            ],
            "stddev_samp": [
                249
            ],
            "sum": [
                250
            ],
            "var_pop": [
                251
            ],
            "var_samp": [
                252
            ],
            "variance": [
                253
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_avg_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_bool_exp": {
            "_and": [
                242
            ],
            "_not": [
                242
            ],
            "_or": [
                242
            ],
            "action": [
                6
            ],
            "createdAt": [
                784
            ],
            "data": [
                385
            ],
            "id": [
                4
            ],
            "index": [
                6
            ],
            "level": [
                4
            ],
            "modelName": [
                6
            ],
            "modelPk": [
                6
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_max_fields": {
            "action": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "index": [
                5
            ],
            "level": [
                3
            ],
            "modelName": [
                5
            ],
            "modelPk": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_min_fields": {
            "action": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "index": [
                5
            ],
            "level": [
                3
            ],
            "modelName": [
                5
            ],
            "modelPk": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_order_by": {
            "action": [
                544
            ],
            "createdAt": [
                544
            ],
            "data": [
                544
            ],
            "id": [
                544
            ],
            "index": [
                544
            ],
            "level": [
                544
            ],
            "modelName": [
                544
            ],
            "modelPk": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_select_column": {},
        "dipdup_model_update_stddev_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_stddev_pop_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_stddev_samp_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_sum_fields": {
            "id": [
                3
            ],
            "level": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_var_pop_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_var_samp_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_model_update_variance_fields": {
            "id": [
                2
            ],
            "level": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema": {
            "createdAt": [
                783
            ],
            "hash": [
                5
            ],
            "name": [
                5
            ],
            "reindex": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_aggregate": {
            "aggregate": [
                256
            ],
            "nodes": [
                254
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_aggregate_fields": {
            "count": [
                3,
                {
                    "columns": [
                        261,
                        "[dipdup_schema_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                258
            ],
            "min": [
                259
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_bool_exp": {
            "_and": [
                257
            ],
            "_not": [
                257
            ],
            "_or": [
                257
            ],
            "createdAt": [
                784
            ],
            "hash": [
                6
            ],
            "name": [
                6
            ],
            "reindex": [
                6
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_max_fields": {
            "createdAt": [
                783
            ],
            "hash": [
                5
            ],
            "name": [
                5
            ],
            "reindex": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_min_fields": {
            "createdAt": [
                783
            ],
            "hash": [
                5
            ],
            "name": [
                5
            ],
            "reindex": [
                5
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_order_by": {
            "createdAt": [
                544
            ],
            "hash": [
                544
            ],
            "name": [
                544
            ],
            "reindex": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_schema_select_column": {},
        "dipdup_token_metadata": {
            "contract": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "metadata": [
                383,
                {
                    "path": [
                        5
                    ]
                }
            ],
            "network": [
                5
            ],
            "tokenId": [
                5
            ],
            "updateId": [
                3
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_aggregate": {
            "aggregate": [
                264
            ],
            "nodes": [
                262
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_aggregate_fields": {
            "avg": [
                265
            ],
            "count": [
                3,
                {
                    "columns": [
                        270,
                        "[dipdup_token_metadata_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                267
            ],
            "min": [
                268
            ],
            "stddev": [
                271
            ],
            "stddev_pop": [
                272
            ],
            "stddev_samp": [
                273
            ],
            "sum": [
                274
            ],
            "var_pop": [
                275
            ],
            "var_samp": [
                276
            ],
            "variance": [
                277
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_avg_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_bool_exp": {
            "_and": [
                266
            ],
            "_not": [
                266
            ],
            "_or": [
                266
            ],
            "contract": [
                6
            ],
            "createdAt": [
                784
            ],
            "id": [
                4
            ],
            "metadata": [
                385
            ],
            "network": [
                6
            ],
            "tokenId": [
                6
            ],
            "updateId": [
                4
            ],
            "updatedAt": [
                784
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_max_fields": {
            "contract": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "network": [
                5
            ],
            "tokenId": [
                5
            ],
            "updateId": [
                3
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_min_fields": {
            "contract": [
                5
            ],
            "createdAt": [
                783
            ],
            "id": [
                3
            ],
            "network": [
                5
            ],
            "tokenId": [
                5
            ],
            "updateId": [
                3
            ],
            "updatedAt": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_order_by": {
            "contract": [
                544
            ],
            "createdAt": [
                544
            ],
            "id": [
                544
            ],
            "metadata": [
                544
            ],
            "network": [
                544
            ],
            "tokenId": [
                544
            ],
            "updateId": [
                544
            ],
            "updatedAt": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_select_column": {},
        "dipdup_token_metadata_stddev_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_stddev_pop_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_stddev_samp_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_sum_fields": {
            "id": [
                3
            ],
            "updateId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_var_pop_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_var_samp_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "dipdup_token_metadata_variance_fields": {
            "id": [
                2
            ],
            "updateId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat": {
            "charisma": [
                3
            ],
            "class_": [
                5
            ],
            "creator": [
                866
            ],
            "creatorId": [
                5
            ],
            "influence": [
                3
            ],
            "ipfs": [
                285
            ],
            "ipfsId": [
                5
            ],
            "listTime": [
                783
            ],
            "mintTime": [
                783
            ],
            "onSale": [
                0
            ],
            "owner": [
                866
            ],
            "ownerId": [
                5
            ],
            "price": [
                515
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_aggregate": {
            "aggregate": [
                280
            ],
            "nodes": [
                278
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_aggregate_fields": {
            "avg": [
                282
            ],
            "count": [
                3,
                {
                    "columns": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                293
            ],
            "min": [
                295
            ],
            "stddev": [
                299
            ],
            "stddev_pop": [
                301
            ],
            "stddev_samp": [
                303
            ],
            "sum": [
                305
            ],
            "var_pop": [
                307
            ],
            "var_samp": [
                309
            ],
            "variance": [
                311
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_aggregate_order_by": {
            "avg": [
                283
            ],
            "count": [
                544
            ],
            "max": [
                294
            ],
            "min": [
                296
            ],
            "stddev": [
                300
            ],
            "stddev_pop": [
                302
            ],
            "stddev_samp": [
                304
            ],
            "sum": [
                306
            ],
            "var_pop": [
                308
            ],
            "var_samp": [
                310
            ],
            "variance": [
                312
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_avg_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_avg_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_bool_exp": {
            "_and": [
                284
            ],
            "_not": [
                284
            ],
            "_or": [
                284
            ],
            "charisma": [
                4
            ],
            "class_": [
                6
            ],
            "creator": [
                869
            ],
            "creatorId": [
                6
            ],
            "influence": [
                4
            ],
            "ipfs": [
                288
            ],
            "ipfsId": [
                6
            ],
            "listTime": [
                784
            ],
            "mintTime": [
                784
            ],
            "onSale": [
                1
            ],
            "owner": [
                869
            ],
            "ownerId": [
                6
            ],
            "price": [
                516
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs": {
            "arm": [
                5
            ],
            "background": [
                5
            ],
            "cloth": [
                5
            ],
            "description": [
                5
            ],
            "diplomats": [
                278,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "diplomats_aggregate": [
                279,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "headdress": [
                5
            ],
            "image": [
                5
            ],
            "katana": [
                5
            ],
            "name": [
                5
            ],
            "skin": [
                5
            ],
            "status": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "uri": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_aggregate": {
            "aggregate": [
                287
            ],
            "nodes": [
                285
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_aggregate_fields": {
            "count": [
                3,
                {
                    "columns": [
                        292,
                        "[diplomat_ipfs_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                289
            ],
            "min": [
                290
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_bool_exp": {
            "_and": [
                288
            ],
            "_not": [
                288
            ],
            "_or": [
                288
            ],
            "arm": [
                6
            ],
            "background": [
                6
            ],
            "cloth": [
                6
            ],
            "description": [
                6
            ],
            "diplomats": [
                284
            ],
            "headdress": [
                6
            ],
            "image": [
                6
            ],
            "katana": [
                6
            ],
            "name": [
                6
            ],
            "skin": [
                6
            ],
            "status": [
                6
            ],
            "symbol": [
                6
            ],
            "thumbnail": [
                6
            ],
            "uri": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_max_fields": {
            "arm": [
                5
            ],
            "background": [
                5
            ],
            "cloth": [
                5
            ],
            "description": [
                5
            ],
            "headdress": [
                5
            ],
            "image": [
                5
            ],
            "katana": [
                5
            ],
            "name": [
                5
            ],
            "skin": [
                5
            ],
            "status": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "uri": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_min_fields": {
            "arm": [
                5
            ],
            "background": [
                5
            ],
            "cloth": [
                5
            ],
            "description": [
                5
            ],
            "headdress": [
                5
            ],
            "image": [
                5
            ],
            "katana": [
                5
            ],
            "name": [
                5
            ],
            "skin": [
                5
            ],
            "status": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "uri": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_order_by": {
            "arm": [
                544
            ],
            "background": [
                544
            ],
            "cloth": [
                544
            ],
            "description": [
                544
            ],
            "diplomats_aggregate": [
                281
            ],
            "headdress": [
                544
            ],
            "image": [
                544
            ],
            "katana": [
                544
            ],
            "name": [
                544
            ],
            "skin": [
                544
            ],
            "status": [
                544
            ],
            "symbol": [
                544
            ],
            "thumbnail": [
                544
            ],
            "uri": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_ipfs_select_column": {},
        "diplomat_max_fields": {
            "charisma": [
                3
            ],
            "class_": [
                5
            ],
            "creatorId": [
                5
            ],
            "influence": [
                3
            ],
            "ipfsId": [
                5
            ],
            "listTime": [
                783
            ],
            "mintTime": [
                783
            ],
            "ownerId": [
                5
            ],
            "price": [
                515
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_max_order_by": {
            "charisma": [
                544
            ],
            "class_": [
                544
            ],
            "creatorId": [
                544
            ],
            "influence": [
                544
            ],
            "ipfsId": [
                544
            ],
            "listTime": [
                544
            ],
            "mintTime": [
                544
            ],
            "ownerId": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_min_fields": {
            "charisma": [
                3
            ],
            "class_": [
                5
            ],
            "creatorId": [
                5
            ],
            "influence": [
                3
            ],
            "ipfsId": [
                5
            ],
            "listTime": [
                783
            ],
            "mintTime": [
                783
            ],
            "ownerId": [
                5
            ],
            "price": [
                515
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_min_order_by": {
            "charisma": [
                544
            ],
            "class_": [
                544
            ],
            "creatorId": [
                544
            ],
            "influence": [
                544
            ],
            "ipfsId": [
                544
            ],
            "listTime": [
                544
            ],
            "mintTime": [
                544
            ],
            "ownerId": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_order_by": {
            "charisma": [
                544
            ],
            "class_": [
                544
            ],
            "creator": [
                872
            ],
            "creatorId": [
                544
            ],
            "influence": [
                544
            ],
            "ipfs": [
                291
            ],
            "ipfsId": [
                544
            ],
            "listTime": [
                544
            ],
            "mintTime": [
                544
            ],
            "onSale": [
                544
            ],
            "owner": [
                872
            ],
            "ownerId": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_select_column": {},
        "diplomat_stddev_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_stddev_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_stddev_pop_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_stddev_pop_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_stddev_samp_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_stddev_samp_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_sum_fields": {
            "charisma": [
                3
            ],
            "influence": [
                3
            ],
            "price": [
                515
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_sum_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_var_pop_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_var_pop_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_var_samp_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_var_samp_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_variance_fields": {
            "charisma": [
                2
            ],
            "influence": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "diplomat_variance_order_by": {
            "charisma": [
                544
            ],
            "influence": [
                544
            ],
            "price": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge": {
            "acceptor": [
                866
            ],
            "acceptorId": [
                5
            ],
            "acceptorLock": [
                34
            ],
            "acceptorLockId": [
                3
            ],
            "challengeId": [
                3
            ],
            "creator": [
                866
            ],
            "creatorId": [
                5
            ],
            "creatorLock": [
                34
            ],
            "creatorLockId": [
                3
            ],
            "extra": [
                383,
                {
                    "path": [
                        5
                    ]
                }
            ],
            "status": [
                5
            ],
            "time": [
                783
            ],
            "winner": [
                866
            ],
            "winnerId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_aggregate": {
            "aggregate": [
                315
            ],
            "nodes": [
                313
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_aggregate_fields": {
            "avg": [
                317
            ],
            "count": [
                3,
                {
                    "columns": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                320
            ],
            "min": [
                322
            ],
            "stddev": [
                326
            ],
            "stddev_pop": [
                328
            ],
            "stddev_samp": [
                330
            ],
            "sum": [
                332
            ],
            "var_pop": [
                334
            ],
            "var_samp": [
                336
            ],
            "variance": [
                338
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_aggregate_order_by": {
            "avg": [
                318
            ],
            "count": [
                544
            ],
            "max": [
                321
            ],
            "min": [
                323
            ],
            "stddev": [
                327
            ],
            "stddev_pop": [
                329
            ],
            "stddev_samp": [
                331
            ],
            "sum": [
                333
            ],
            "var_pop": [
                335
            ],
            "var_samp": [
                337
            ],
            "variance": [
                339
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_avg_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_avg_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_bool_exp": {
            "_and": [
                319
            ],
            "_not": [
                319
            ],
            "_or": [
                319
            ],
            "acceptor": [
                869
            ],
            "acceptorId": [
                6
            ],
            "acceptorLock": [
                40
            ],
            "acceptorLockId": [
                4
            ],
            "challengeId": [
                4
            ],
            "creator": [
                869
            ],
            "creatorId": [
                6
            ],
            "creatorLock": [
                40
            ],
            "creatorLockId": [
                4
            ],
            "extra": [
                385
            ],
            "status": [
                6
            ],
            "time": [
                784
            ],
            "winner": [
                869
            ],
            "winnerId": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_max_fields": {
            "acceptorId": [
                5
            ],
            "acceptorLockId": [
                3
            ],
            "challengeId": [
                3
            ],
            "creatorId": [
                5
            ],
            "creatorLockId": [
                3
            ],
            "status": [
                5
            ],
            "time": [
                783
            ],
            "winnerId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_max_order_by": {
            "acceptorId": [
                544
            ],
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "status": [
                544
            ],
            "time": [
                544
            ],
            "winnerId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_min_fields": {
            "acceptorId": [
                5
            ],
            "acceptorLockId": [
                3
            ],
            "challengeId": [
                3
            ],
            "creatorId": [
                5
            ],
            "creatorLockId": [
                3
            ],
            "status": [
                5
            ],
            "time": [
                783
            ],
            "winnerId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_min_order_by": {
            "acceptorId": [
                544
            ],
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "status": [
                544
            ],
            "time": [
                544
            ],
            "winnerId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_order_by": {
            "acceptor": [
                872
            ],
            "acceptorId": [
                544
            ],
            "acceptorLock": [
                77
            ],
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creator": [
                872
            ],
            "creatorId": [
                544
            ],
            "creatorLock": [
                77
            ],
            "creatorLockId": [
                544
            ],
            "extra": [
                544
            ],
            "status": [
                544
            ],
            "time": [
                544
            ],
            "winner": [
                872
            ],
            "winnerId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_select_column": {},
        "direct_challenge_stddev_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_stddev_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_stddev_pop_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_stddev_pop_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_stddev_samp_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_stddev_samp_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_sum_fields": {
            "acceptorLockId": [
                3
            ],
            "challengeId": [
                3
            ],
            "creatorLockId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_sum_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_var_pop_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_var_pop_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_var_samp_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_var_samp_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_variance_fields": {
            "acceptorLockId": [
                2
            ],
            "challengeId": [
                2
            ],
            "creatorLockId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "direct_challenge_variance_order_by": {
            "acceptorLockId": [
                544
            ],
            "challengeId": [
                544
            ],
            "creatorLockId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft": {
            "id": [
                3
            ],
            "metadata": [
                367
            ],
            "metadataId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_aggregate": {
            "aggregate": [
                342
            ],
            "nodes": [
                340
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_aggregate_fields": {
            "avg": [
                344
            ],
            "count": [
                3,
                {
                    "columns": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                347
            ],
            "min": [
                349
            ],
            "stddev": [
                353
            ],
            "stddev_pop": [
                355
            ],
            "stddev_samp": [
                357
            ],
            "sum": [
                359
            ],
            "var_pop": [
                361
            ],
            "var_samp": [
                363
            ],
            "variance": [
                365
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_aggregate_order_by": {
            "avg": [
                345
            ],
            "count": [
                544
            ],
            "max": [
                348
            ],
            "min": [
                350
            ],
            "stddev": [
                354
            ],
            "stddev_pop": [
                356
            ],
            "stddev_samp": [
                358
            ],
            "sum": [
                360
            ],
            "var_pop": [
                362
            ],
            "var_samp": [
                364
            ],
            "variance": [
                366
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_avg_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_avg_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_bool_exp": {
            "_and": [
                346
            ],
            "_not": [
                346
            ],
            "_or": [
                346
            ],
            "id": [
                4
            ],
            "metadata": [
                371
            ],
            "metadataId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_max_fields": {
            "id": [
                3
            ],
            "metadataId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_max_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_min_fields": {
            "id": [
                3
            ],
            "metadataId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_min_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_order_by": {
            "id": [
                544
            ],
            "metadata": [
                374
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_select_column": {},
        "faucet_nft_stddev_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_stddev_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_stddev_pop_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_stddev_pop_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_stddev_samp_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_stddev_samp_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_sum_fields": {
            "id": [
                3
            ],
            "metadataId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_sum_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_var_pop_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_var_pop_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_var_samp_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_var_samp_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_variance_fields": {
            "id": [
                2
            ],
            "metadataId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "faucet_nft_variance_order_by": {
            "id": [
                544
            ],
            "metadataId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ipfs": {
            "creator": [
                5
            ],
            "description": [
                5
            ],
            "faucetNfts": [
                340,
                {
                    "distinct_on": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        351,
                        "[faucet_nft_order_by!]"
                    ],
                    "where": [
                        346
                    ]
                }
            ],
            "faucetNfts_aggregate": [
                341,
                {
                    "distinct_on": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        351,
                        "[faucet_nft_order_by!]"
                    ],
                    "where": [
                        346
                    ]
                }
            ],
            "id": [
                3
            ],
            "image": [
                5
            ],
            "name": [
                5
            ],
            "stockArtifacts": [
                675,
                {
                    "distinct_on": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        686,
                        "[stock_artifact_order_by!]"
                    ],
                    "where": [
                        681
                    ]
                }
            ],
            "stockArtifacts_aggregate": [
                676,
                {
                    "distinct_on": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        686,
                        "[stock_artifact_order_by!]"
                    ],
                    "where": [
                        681
                    ]
                }
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "tokens": [
                785,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokens_aggregate": [
                786,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "uri": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_aggregate": {
            "aggregate": [
                369
            ],
            "nodes": [
                367
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_aggregate_fields": {
            "avg": [
                370
            ],
            "count": [
                3,
                {
                    "columns": [
                        375,
                        "[ipfs_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                372
            ],
            "min": [
                373
            ],
            "stddev": [
                376
            ],
            "stddev_pop": [
                377
            ],
            "stddev_samp": [
                378
            ],
            "sum": [
                379
            ],
            "var_pop": [
                380
            ],
            "var_samp": [
                381
            ],
            "variance": [
                382
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_avg_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_bool_exp": {
            "_and": [
                371
            ],
            "_not": [
                371
            ],
            "_or": [
                371
            ],
            "creator": [
                6
            ],
            "description": [
                6
            ],
            "faucetNfts": [
                346
            ],
            "id": [
                4
            ],
            "image": [
                6
            ],
            "name": [
                6
            ],
            "stockArtifacts": [
                681
            ],
            "symbol": [
                6
            ],
            "thumbnail": [
                6
            ],
            "tokens": [
                791
            ],
            "uri": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_max_fields": {
            "creator": [
                5
            ],
            "description": [
                5
            ],
            "id": [
                3
            ],
            "image": [
                5
            ],
            "name": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "uri": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_min_fields": {
            "creator": [
                5
            ],
            "description": [
                5
            ],
            "id": [
                3
            ],
            "image": [
                5
            ],
            "name": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "uri": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_order_by": {
            "creator": [
                544
            ],
            "description": [
                544
            ],
            "faucetNfts_aggregate": [
                343
            ],
            "id": [
                544
            ],
            "image": [
                544
            ],
            "name": [
                544
            ],
            "stockArtifacts_aggregate": [
                678
            ],
            "symbol": [
                544
            ],
            "thumbnail": [
                544
            ],
            "tokens_aggregate": [
                788
            ],
            "uri": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_select_column": {},
        "ipfs_stddev_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_stddev_pop_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_stddev_samp_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_sum_fields": {
            "id": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_var_pop_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_var_samp_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ipfs_variance_fields": {
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "jsonb": {},
        "jsonb_cast_exp": {
            "String": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "jsonb_comparison_exp": {
            "_cast": [
                384
            ],
            "_contained_in": [
                383
            ],
            "_contains": [
                383
            ],
            "_eq": [
                383
            ],
            "_gt": [
                383
            ],
            "_gte": [
                383
            ],
            "_has_key": [
                5
            ],
            "_has_keys_all": [
                5
            ],
            "_has_keys_any": [
                5
            ],
            "_in": [
                383
            ],
            "_is_null": [
                0
            ],
            "_lt": [
                383
            ],
            "_lte": [
                383
            ],
            "_neq": [
                383
            ],
            "_nin": [
                383
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource": {
            "enr": [
                515
            ],
            "id": [
                3
            ],
            "startTime": [
                783
            ],
            "uno": [
                515
            ],
            "user": [
                866
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_aggregate": {
            "aggregate": [
                388
            ],
            "nodes": [
                386
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_aggregate_fields": {
            "avg": [
                390
            ],
            "count": [
                3,
                {
                    "columns": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                393
            ],
            "min": [
                395
            ],
            "stddev": [
                399
            ],
            "stddev_pop": [
                401
            ],
            "stddev_samp": [
                403
            ],
            "sum": [
                405
            ],
            "var_pop": [
                407
            ],
            "var_samp": [
                409
            ],
            "variance": [
                411
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_aggregate_order_by": {
            "avg": [
                391
            ],
            "count": [
                544
            ],
            "max": [
                394
            ],
            "min": [
                396
            ],
            "stddev": [
                400
            ],
            "stddev_pop": [
                402
            ],
            "stddev_samp": [
                404
            ],
            "sum": [
                406
            ],
            "var_pop": [
                408
            ],
            "var_samp": [
                410
            ],
            "variance": [
                412
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_avg_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_avg_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_bool_exp": {
            "_and": [
                392
            ],
            "_not": [
                392
            ],
            "_or": [
                392
            ],
            "enr": [
                516
            ],
            "id": [
                4
            ],
            "startTime": [
                784
            ],
            "uno": [
                516
            ],
            "user": [
                869
            ],
            "userId": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_max_fields": {
            "enr": [
                515
            ],
            "id": [
                3
            ],
            "startTime": [
                783
            ],
            "uno": [
                515
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_max_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "startTime": [
                544
            ],
            "uno": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_min_fields": {
            "enr": [
                515
            ],
            "id": [
                3
            ],
            "startTime": [
                783
            ],
            "uno": [
                515
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_min_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "startTime": [
                544
            ],
            "uno": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "startTime": [
                544
            ],
            "uno": [
                544
            ],
            "user": [
                872
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_select_column": {},
        "locked_resource_stddev_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_stddev_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_stddev_pop_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_stddev_pop_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_stddev_samp_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_stddev_samp_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_sum_fields": {
            "enr": [
                515
            ],
            "id": [
                3
            ],
            "uno": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_sum_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_var_pop_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_var_pop_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_var_samp_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_var_samp_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_variance_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_resource_variance_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token": {
            "amount": [
                515
            ],
            "battleLock": [
                34
            ],
            "battleLockId": [
                3
            ],
            "id": [
                3
            ],
            "token": [
                874
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_aggregate": {
            "aggregate": [
                415
            ],
            "nodes": [
                413
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_aggregate_fields": {
            "avg": [
                417
            ],
            "count": [
                3,
                {
                    "columns": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                420
            ],
            "min": [
                422
            ],
            "stddev": [
                426
            ],
            "stddev_pop": [
                428
            ],
            "stddev_samp": [
                430
            ],
            "sum": [
                432
            ],
            "var_pop": [
                434
            ],
            "var_samp": [
                436
            ],
            "variance": [
                438
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_aggregate_order_by": {
            "avg": [
                418
            ],
            "count": [
                544
            ],
            "max": [
                421
            ],
            "min": [
                423
            ],
            "stddev": [
                427
            ],
            "stddev_pop": [
                429
            ],
            "stddev_samp": [
                431
            ],
            "sum": [
                433
            ],
            "var_pop": [
                435
            ],
            "var_samp": [
                437
            ],
            "variance": [
                439
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_avg_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_avg_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_bool_exp": {
            "_and": [
                419
            ],
            "_not": [
                419
            ],
            "_or": [
                419
            ],
            "amount": [
                516
            ],
            "battleLock": [
                40
            ],
            "battleLockId": [
                4
            ],
            "id": [
                4
            ],
            "token": [
                878
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_max_fields": {
            "amount": [
                515
            ],
            "battleLockId": [
                3
            ],
            "id": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_max_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_min_fields": {
            "amount": [
                515
            ],
            "battleLockId": [
                3
            ],
            "id": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_min_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_order_by": {
            "amount": [
                544
            ],
            "battleLock": [
                77
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "token": [
                881
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_select_column": {},
        "locked_token_stddev_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_stddev_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_stddev_pop_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_stddev_pop_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_stddev_samp_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_stddev_samp_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_sum_fields": {
            "amount": [
                515
            ],
            "battleLockId": [
                3
            ],
            "id": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_sum_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_var_pop_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_var_pop_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_var_samp_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_var_samp_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_variance_fields": {
            "amount": [
                2
            ],
            "battleLockId": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "locked_token_variance_order_by": {
            "amount": [
                544
            ],
            "battleLockId": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery": {
            "finished": [
                0
            ],
            "id": [
                3
            ],
            "participantCount": [
                3
            ],
            "participants": [
                448,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "participants_aggregate": [
                449,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "startTime": [
                783
            ],
            "ticketCount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_aggregate": {
            "aggregate": [
                442
            ],
            "nodes": [
                440
            ],
            "__typename": [
                5
            ]
        },
        "lottery_aggregate_fields": {
            "avg": [
                443
            ],
            "count": [
                3,
                {
                    "columns": [
                        475,
                        "[lottery_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                445
            ],
            "min": [
                446
            ],
            "stddev": [
                476
            ],
            "stddev_pop": [
                477
            ],
            "stddev_samp": [
                478
            ],
            "sum": [
                479
            ],
            "var_pop": [
                480
            ],
            "var_samp": [
                481
            ],
            "variance": [
                482
            ],
            "__typename": [
                5
            ]
        },
        "lottery_avg_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_bool_exp": {
            "_and": [
                444
            ],
            "_not": [
                444
            ],
            "_or": [
                444
            ],
            "finished": [
                1
            ],
            "id": [
                4
            ],
            "participantCount": [
                4
            ],
            "participants": [
                454
            ],
            "startTime": [
                784
            ],
            "ticketCount": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "lottery_max_fields": {
            "id": [
                3
            ],
            "participantCount": [
                3
            ],
            "startTime": [
                783
            ],
            "ticketCount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_min_fields": {
            "id": [
                3
            ],
            "participantCount": [
                3
            ],
            "startTime": [
                783
            ],
            "ticketCount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_order_by": {
            "finished": [
                544
            ],
            "id": [
                544
            ],
            "participantCount": [
                544
            ],
            "participants_aggregate": [
                451
            ],
            "startTime": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant": {
            "entries": [
                3
            ],
            "id": [
                3
            ],
            "lottery": [
                440
            ],
            "lotteryId": [
                3
            ],
            "user": [
                866
            ],
            "userId": [
                5
            ],
            "won": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_aggregate": {
            "aggregate": [
                450
            ],
            "nodes": [
                448
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_aggregate_fields": {
            "avg": [
                452
            ],
            "count": [
                3,
                {
                    "columns": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                455
            ],
            "min": [
                457
            ],
            "stddev": [
                461
            ],
            "stddev_pop": [
                463
            ],
            "stddev_samp": [
                465
            ],
            "sum": [
                467
            ],
            "var_pop": [
                469
            ],
            "var_samp": [
                471
            ],
            "variance": [
                473
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_aggregate_order_by": {
            "avg": [
                453
            ],
            "count": [
                544
            ],
            "max": [
                456
            ],
            "min": [
                458
            ],
            "stddev": [
                462
            ],
            "stddev_pop": [
                464
            ],
            "stddev_samp": [
                466
            ],
            "sum": [
                468
            ],
            "var_pop": [
                470
            ],
            "var_samp": [
                472
            ],
            "variance": [
                474
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_avg_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_avg_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_bool_exp": {
            "_and": [
                454
            ],
            "_not": [
                454
            ],
            "_or": [
                454
            ],
            "entries": [
                4
            ],
            "id": [
                4
            ],
            "lottery": [
                444
            ],
            "lotteryId": [
                4
            ],
            "user": [
                869
            ],
            "userId": [
                6
            ],
            "won": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_max_fields": {
            "entries": [
                3
            ],
            "id": [
                3
            ],
            "lotteryId": [
                3
            ],
            "userId": [
                5
            ],
            "won": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_max_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "userId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_min_fields": {
            "entries": [
                3
            ],
            "id": [
                3
            ],
            "lotteryId": [
                3
            ],
            "userId": [
                5
            ],
            "won": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_min_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "userId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lottery": [
                447
            ],
            "lotteryId": [
                544
            ],
            "user": [
                872
            ],
            "userId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_select_column": {},
        "lottery_participant_stddev_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_stddev_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_stddev_pop_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_stddev_pop_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_stddev_samp_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_stddev_samp_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_sum_fields": {
            "entries": [
                3
            ],
            "id": [
                3
            ],
            "lotteryId": [
                3
            ],
            "won": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_sum_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_var_pop_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_var_pop_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_var_samp_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_var_samp_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_variance_fields": {
            "entries": [
                2
            ],
            "id": [
                2
            ],
            "lotteryId": [
                2
            ],
            "won": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_participant_variance_order_by": {
            "entries": [
                544
            ],
            "id": [
                544
            ],
            "lotteryId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "lottery_select_column": {},
        "lottery_stddev_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_stddev_pop_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_stddev_samp_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_sum_fields": {
            "id": [
                3
            ],
            "participantCount": [
                3
            ],
            "ticketCount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "lottery_var_pop_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_var_samp_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "lottery_variance_fields": {
            "id": [
                2
            ],
            "participantCount": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace": {
            "id": [
                3
            ],
            "onSale": [
                0
            ],
            "price": [
                515
            ],
            "time": [
                783
            ],
            "tokenId": [
                3
            ],
            "tokens": [
                785,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokens_aggregate": [
                786,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_aggregate": {
            "aggregate": [
                485
            ],
            "nodes": [
                483
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_aggregate_fields": {
            "avg": [
                486
            ],
            "count": [
                3,
                {
                    "columns": [
                        491,
                        "[marketplace_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                488
            ],
            "min": [
                489
            ],
            "stddev": [
                508
            ],
            "stddev_pop": [
                509
            ],
            "stddev_samp": [
                510
            ],
            "sum": [
                511
            ],
            "var_pop": [
                512
            ],
            "var_samp": [
                513
            ],
            "variance": [
                514
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_avg_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_bool_exp": {
            "_and": [
                487
            ],
            "_not": [
                487
            ],
            "_or": [
                487
            ],
            "id": [
                4
            ],
            "onSale": [
                1
            ],
            "price": [
                516
            ],
            "time": [
                784
            ],
            "tokenId": [
                4
            ],
            "tokens": [
                791
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_max_fields": {
            "id": [
                3
            ],
            "price": [
                515
            ],
            "time": [
                783
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_min_fields": {
            "id": [
                3
            ],
            "price": [
                515
            ],
            "time": [
                783
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_order_by": {
            "id": [
                544
            ],
            "onSale": [
                544
            ],
            "price": [
                544
            ],
            "time": [
                544
            ],
            "tokenId": [
                544
            ],
            "tokens_aggregate": [
                788
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_select_column": {},
        "marketplace_stat": {
            "id": [
                3
            ],
            "lastUpdatedAt": [
                783
            ],
            "name": [
                5
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_aggregate": {
            "aggregate": [
                494
            ],
            "nodes": [
                492
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_aggregate_fields": {
            "avg": [
                495
            ],
            "count": [
                3,
                {
                    "columns": [
                        500,
                        "[marketplace_stat_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                497
            ],
            "min": [
                498
            ],
            "stddev": [
                501
            ],
            "stddev_pop": [
                502
            ],
            "stddev_samp": [
                503
            ],
            "sum": [
                504
            ],
            "var_pop": [
                505
            ],
            "var_samp": [
                506
            ],
            "variance": [
                507
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_avg_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_bool_exp": {
            "_and": [
                496
            ],
            "_not": [
                496
            ],
            "_or": [
                496
            ],
            "id": [
                4
            ],
            "lastUpdatedAt": [
                784
            ],
            "name": [
                6
            ],
            "value": [
                516
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_max_fields": {
            "id": [
                3
            ],
            "lastUpdatedAt": [
                783
            ],
            "name": [
                5
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_min_fields": {
            "id": [
                3
            ],
            "lastUpdatedAt": [
                783
            ],
            "name": [
                5
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_order_by": {
            "id": [
                544
            ],
            "lastUpdatedAt": [
                544
            ],
            "name": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_select_column": {},
        "marketplace_stat_stddev_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_stddev_pop_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_stddev_samp_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_sum_fields": {
            "id": [
                3
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_var_pop_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_var_samp_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stat_variance_fields": {
            "id": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stddev_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stddev_pop_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_stddev_samp_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_sum_fields": {
            "id": [
                3
            ],
            "price": [
                515
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_var_pop_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_var_samp_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "marketplace_variance_fields": {
            "id": [
                2
            ],
            "price": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "numeric": {},
        "numeric_comparison_exp": {
            "_eq": [
                515
            ],
            "_gt": [
                515
            ],
            "_gte": [
                515
            ],
            "_in": [
                515
            ],
            "_is_null": [
                0
            ],
            "_lt": [
                515
            ],
            "_lte": [
                515
            ],
            "_neq": [
                515
            ],
            "_nin": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "operation": {
            "event": [
                5
            ],
            "fromUser": [
                866
            ],
            "fromUserId": [
                5
            ],
            "id": [
                3
            ],
            "itemId": [
                3
            ],
            "time": [
                783
            ],
            "toUser": [
                866
            ],
            "toUserId": [
                5
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "operation_aggregate": {
            "aggregate": [
                519
            ],
            "nodes": [
                517
            ],
            "__typename": [
                5
            ]
        },
        "operation_aggregate_fields": {
            "avg": [
                521
            ],
            "count": [
                3,
                {
                    "columns": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                524
            ],
            "min": [
                526
            ],
            "stddev": [
                530
            ],
            "stddev_pop": [
                532
            ],
            "stddev_samp": [
                534
            ],
            "sum": [
                536
            ],
            "var_pop": [
                538
            ],
            "var_samp": [
                540
            ],
            "variance": [
                542
            ],
            "__typename": [
                5
            ]
        },
        "operation_aggregate_order_by": {
            "avg": [
                522
            ],
            "count": [
                544
            ],
            "max": [
                525
            ],
            "min": [
                527
            ],
            "stddev": [
                531
            ],
            "stddev_pop": [
                533
            ],
            "stddev_samp": [
                535
            ],
            "sum": [
                537
            ],
            "var_pop": [
                539
            ],
            "var_samp": [
                541
            ],
            "variance": [
                543
            ],
            "__typename": [
                5
            ]
        },
        "operation_avg_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_avg_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_bool_exp": {
            "_and": [
                523
            ],
            "_not": [
                523
            ],
            "_or": [
                523
            ],
            "event": [
                6
            ],
            "fromUser": [
                869
            ],
            "fromUserId": [
                6
            ],
            "id": [
                4
            ],
            "itemId": [
                4
            ],
            "time": [
                784
            ],
            "toUser": [
                869
            ],
            "toUserId": [
                6
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "value": [
                516
            ],
            "__typename": [
                5
            ]
        },
        "operation_max_fields": {
            "event": [
                5
            ],
            "fromUserId": [
                5
            ],
            "id": [
                3
            ],
            "itemId": [
                3
            ],
            "time": [
                783
            ],
            "toUserId": [
                5
            ],
            "tokenId": [
                3
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "operation_max_order_by": {
            "event": [
                544
            ],
            "fromUserId": [
                544
            ],
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "time": [
                544
            ],
            "toUserId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_min_fields": {
            "event": [
                5
            ],
            "fromUserId": [
                5
            ],
            "id": [
                3
            ],
            "itemId": [
                3
            ],
            "time": [
                783
            ],
            "toUserId": [
                5
            ],
            "tokenId": [
                3
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "operation_min_order_by": {
            "event": [
                544
            ],
            "fromUserId": [
                544
            ],
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "time": [
                544
            ],
            "toUserId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_order_by": {
            "event": [
                544
            ],
            "fromUser": [
                872
            ],
            "fromUserId": [
                544
            ],
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "time": [
                544
            ],
            "toUser": [
                872
            ],
            "toUserId": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_select_column": {},
        "operation_stddev_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_stddev_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_stddev_pop_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_stddev_pop_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_stddev_samp_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_stddev_samp_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_sum_fields": {
            "id": [
                3
            ],
            "itemId": [
                3
            ],
            "tokenId": [
                3
            ],
            "value": [
                515
            ],
            "__typename": [
                5
            ]
        },
        "operation_sum_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_var_pop_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_var_pop_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_var_samp_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_var_samp_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "operation_variance_fields": {
            "id": [
                2
            ],
            "itemId": [
                2
            ],
            "tokenId": [
                2
            ],
            "value": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "operation_variance_order_by": {
            "id": [
                544
            ],
            "itemId": [
                544
            ],
            "tokenId": [
                544
            ],
            "value": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "order_by": {},
        "primary_sale": {
            "left": [
                3
            ],
            "listingId": [
                3
            ],
            "listingTime": [
                783
            ],
            "price": [
                515
            ],
            "token": [
                785
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_aggregate": {
            "aggregate": [
                547
            ],
            "nodes": [
                545
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_aggregate_fields": {
            "avg": [
                549
            ],
            "count": [
                3,
                {
                    "columns": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                568
            ],
            "min": [
                570
            ],
            "stddev": [
                574
            ],
            "stddev_pop": [
                576
            ],
            "stddev_samp": [
                578
            ],
            "sum": [
                580
            ],
            "var_pop": [
                582
            ],
            "var_samp": [
                584
            ],
            "variance": [
                586
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_aggregate_order_by": {
            "avg": [
                550
            ],
            "count": [
                544
            ],
            "max": [
                569
            ],
            "min": [
                571
            ],
            "stddev": [
                575
            ],
            "stddev_pop": [
                577
            ],
            "stddev_samp": [
                579
            ],
            "sum": [
                581
            ],
            "var_pop": [
                583
            ],
            "var_samp": [
                585
            ],
            "variance": [
                587
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_avg_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_avg_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_bool_exp": {
            "_and": [
                551
            ],
            "_not": [
                551
            ],
            "_or": [
                551
            ],
            "left": [
                4
            ],
            "listingId": [
                4
            ],
            "listingTime": [
                784
            ],
            "price": [
                516
            ],
            "token": [
                791
            ],
            "tokenCount": [
                4
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter": {
            "address": [
                5
            ],
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_aggregate": {
            "aggregate": [
                554
            ],
            "nodes": [
                552
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_aggregate_fields": {
            "avg": [
                555
            ],
            "count": [
                3,
                {
                    "columns": [
                        560,
                        "[primary_sale_counter_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                557
            ],
            "min": [
                558
            ],
            "stddev": [
                561
            ],
            "stddev_pop": [
                562
            ],
            "stddev_samp": [
                563
            ],
            "sum": [
                564
            ],
            "var_pop": [
                565
            ],
            "var_samp": [
                566
            ],
            "variance": [
                567
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_avg_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_bool_exp": {
            "_and": [
                556
            ],
            "_not": [
                556
            ],
            "_or": [
                556
            ],
            "address": [
                6
            ],
            "counter": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_max_fields": {
            "address": [
                5
            ],
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_min_fields": {
            "address": [
                5
            ],
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_order_by": {
            "address": [
                544
            ],
            "counter": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_select_column": {},
        "primary_sale_counter_stddev_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_stddev_pop_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_stddev_samp_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_sum_fields": {
            "counter": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_var_pop_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_var_samp_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_counter_variance_fields": {
            "counter": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_max_fields": {
            "left": [
                3
            ],
            "listingId": [
                3
            ],
            "listingTime": [
                783
            ],
            "price": [
                515
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_max_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "listingTime": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_min_fields": {
            "left": [
                3
            ],
            "listingId": [
                3
            ],
            "listingTime": [
                783
            ],
            "price": [
                515
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_min_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "listingTime": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "listingTime": [
                544
            ],
            "price": [
                544
            ],
            "token": [
                796
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_select_column": {},
        "primary_sale_stddev_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_stddev_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_stddev_pop_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_stddev_pop_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_stddev_samp_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_stddev_samp_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_sum_fields": {
            "left": [
                3
            ],
            "listingId": [
                3
            ],
            "price": [
                515
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_sum_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_var_pop_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_var_pop_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_var_samp_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_var_samp_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_variance_fields": {
            "left": [
                2
            ],
            "listingId": [
                2
            ],
            "price": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "primary_sale_variance_order_by": {
            "left": [
                544
            ],
            "listingId": [
                544
            ],
            "price": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle": {
            "endTime": [
                783
            ],
            "finished": [
                0
            ],
            "participantCount": [
                3
            ],
            "participants": [
                600,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "participants_aggregate": [
                601,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "price": [
                515
            ],
            "raffleId": [
                3
            ],
            "startTime": [
                783
            ],
            "token": [
                785
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "raffle_aggregate": {
            "aggregate": [
                590
            ],
            "nodes": [
                588
            ],
            "__typename": [
                5
            ]
        },
        "raffle_aggregate_fields": {
            "avg": [
                592
            ],
            "count": [
                3,
                {
                    "columns": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                595
            ],
            "min": [
                597
            ],
            "stddev": [
                628
            ],
            "stddev_pop": [
                630
            ],
            "stddev_samp": [
                632
            ],
            "sum": [
                634
            ],
            "var_pop": [
                636
            ],
            "var_samp": [
                638
            ],
            "variance": [
                640
            ],
            "__typename": [
                5
            ]
        },
        "raffle_aggregate_order_by": {
            "avg": [
                593
            ],
            "count": [
                544
            ],
            "max": [
                596
            ],
            "min": [
                598
            ],
            "stddev": [
                629
            ],
            "stddev_pop": [
                631
            ],
            "stddev_samp": [
                633
            ],
            "sum": [
                635
            ],
            "var_pop": [
                637
            ],
            "var_samp": [
                639
            ],
            "variance": [
                641
            ],
            "__typename": [
                5
            ]
        },
        "raffle_avg_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_avg_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_bool_exp": {
            "_and": [
                594
            ],
            "_not": [
                594
            ],
            "_or": [
                594
            ],
            "endTime": [
                784
            ],
            "finished": [
                1
            ],
            "participantCount": [
                4
            ],
            "participants": [
                606
            ],
            "price": [
                516
            ],
            "raffleId": [
                4
            ],
            "startTime": [
                784
            ],
            "token": [
                791
            ],
            "tokenCount": [
                4
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "raffle_max_fields": {
            "endTime": [
                783
            ],
            "participantCount": [
                3
            ],
            "price": [
                515
            ],
            "raffleId": [
                3
            ],
            "startTime": [
                783
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "raffle_max_order_by": {
            "endTime": [
                544
            ],
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "startTime": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_min_fields": {
            "endTime": [
                783
            ],
            "participantCount": [
                3
            ],
            "price": [
                515
            ],
            "raffleId": [
                3
            ],
            "startTime": [
                783
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "raffle_min_order_by": {
            "endTime": [
                544
            ],
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "startTime": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_order_by": {
            "endTime": [
                544
            ],
            "finished": [
                544
            ],
            "participantCount": [
                544
            ],
            "participants_aggregate": [
                603
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "startTime": [
                544
            ],
            "token": [
                796
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant": {
            "claimed": [
                0
            ],
            "id": [
                3
            ],
            "raffle": [
                588
            ],
            "raffleId": [
                3
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "user": [
                866
            ],
            "userId": [
                5
            ],
            "won": [
                0
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_aggregate": {
            "aggregate": [
                602
            ],
            "nodes": [
                600
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_aggregate_fields": {
            "avg": [
                604
            ],
            "count": [
                3,
                {
                    "columns": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                607
            ],
            "min": [
                609
            ],
            "stddev": [
                613
            ],
            "stddev_pop": [
                615
            ],
            "stddev_samp": [
                617
            ],
            "sum": [
                619
            ],
            "var_pop": [
                621
            ],
            "var_samp": [
                623
            ],
            "variance": [
                625
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_aggregate_order_by": {
            "avg": [
                605
            ],
            "count": [
                544
            ],
            "max": [
                608
            ],
            "min": [
                610
            ],
            "stddev": [
                614
            ],
            "stddev_pop": [
                616
            ],
            "stddev_samp": [
                618
            ],
            "sum": [
                620
            ],
            "var_pop": [
                622
            ],
            "var_samp": [
                624
            ],
            "variance": [
                626
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_avg_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_avg_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_bool_exp": {
            "_and": [
                606
            ],
            "_not": [
                606
            ],
            "_or": [
                606
            ],
            "claimed": [
                1
            ],
            "id": [
                4
            ],
            "raffle": [
                594
            ],
            "raffleId": [
                4
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "user": [
                869
            ],
            "userId": [
                6
            ],
            "won": [
                1
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_max_fields": {
            "id": [
                3
            ],
            "raffleId": [
                3
            ],
            "tokenId": [
                3
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_max_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_min_fields": {
            "id": [
                3
            ],
            "raffleId": [
                3
            ],
            "tokenId": [
                3
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_min_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_order_by": {
            "claimed": [
                544
            ],
            "id": [
                544
            ],
            "raffle": [
                599
            ],
            "raffleId": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "user": [
                872
            ],
            "userId": [
                544
            ],
            "won": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_select_column": {},
        "raffle_participant_stddev_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_stddev_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_stddev_pop_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_stddev_pop_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_stddev_samp_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_stddev_samp_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_sum_fields": {
            "id": [
                3
            ],
            "raffleId": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_sum_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_var_pop_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_var_pop_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_var_samp_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_var_samp_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_variance_fields": {
            "id": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_participant_variance_order_by": {
            "id": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_select_column": {},
        "raffle_stddev_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_stddev_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_stddev_pop_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_stddev_pop_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_stddev_samp_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_stddev_samp_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_sum_fields": {
            "participantCount": [
                3
            ],
            "price": [
                515
            ],
            "raffleId": [
                3
            ],
            "tokenCount": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "raffle_sum_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_var_pop_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_var_pop_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_var_samp_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_var_samp_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "raffle_variance_fields": {
            "participantCount": [
                2
            ],
            "price": [
                2
            ],
            "raffleId": [
                2
            ],
            "tokenCount": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "raffle_variance_order_by": {
            "participantCount": [
                544
            ],
            "price": [
                544
            ],
            "raffleId": [
                544
            ],
            "tokenCount": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale": {
            "amount": [
                515
            ],
            "buyer": [
                866
            ],
            "buyerId": [
                5
            ],
            "id": [
                3
            ],
            "seller": [
                866
            ],
            "sellerId": [
                5
            ],
            "time": [
                783
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "sale_aggregate": {
            "aggregate": [
                644
            ],
            "nodes": [
                642
            ],
            "__typename": [
                5
            ]
        },
        "sale_aggregate_fields": {
            "avg": [
                646
            ],
            "count": [
                3,
                {
                    "columns": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                649
            ],
            "min": [
                651
            ],
            "stddev": [
                655
            ],
            "stddev_pop": [
                657
            ],
            "stddev_samp": [
                659
            ],
            "sum": [
                661
            ],
            "var_pop": [
                663
            ],
            "var_samp": [
                665
            ],
            "variance": [
                667
            ],
            "__typename": [
                5
            ]
        },
        "sale_aggregate_order_by": {
            "avg": [
                647
            ],
            "count": [
                544
            ],
            "max": [
                650
            ],
            "min": [
                652
            ],
            "stddev": [
                656
            ],
            "stddev_pop": [
                658
            ],
            "stddev_samp": [
                660
            ],
            "sum": [
                662
            ],
            "var_pop": [
                664
            ],
            "var_samp": [
                666
            ],
            "variance": [
                668
            ],
            "__typename": [
                5
            ]
        },
        "sale_avg_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_avg_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_bool_exp": {
            "_and": [
                648
            ],
            "_not": [
                648
            ],
            "_or": [
                648
            ],
            "amount": [
                516
            ],
            "buyer": [
                869
            ],
            "buyerId": [
                6
            ],
            "id": [
                4
            ],
            "seller": [
                869
            ],
            "sellerId": [
                6
            ],
            "time": [
                784
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "sale_max_fields": {
            "amount": [
                515
            ],
            "buyerId": [
                5
            ],
            "id": [
                3
            ],
            "sellerId": [
                5
            ],
            "time": [
                783
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "sale_max_order_by": {
            "amount": [
                544
            ],
            "buyerId": [
                544
            ],
            "id": [
                544
            ],
            "sellerId": [
                544
            ],
            "time": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_min_fields": {
            "amount": [
                515
            ],
            "buyerId": [
                5
            ],
            "id": [
                3
            ],
            "sellerId": [
                5
            ],
            "time": [
                783
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "sale_min_order_by": {
            "amount": [
                544
            ],
            "buyerId": [
                544
            ],
            "id": [
                544
            ],
            "sellerId": [
                544
            ],
            "time": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_order_by": {
            "amount": [
                544
            ],
            "buyer": [
                872
            ],
            "buyerId": [
                544
            ],
            "id": [
                544
            ],
            "seller": [
                872
            ],
            "sellerId": [
                544
            ],
            "time": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_select_column": {},
        "sale_stddev_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_stddev_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_stddev_pop_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_stddev_pop_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_stddev_samp_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_stddev_samp_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_sum_fields": {
            "amount": [
                515
            ],
            "id": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "sale_sum_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_var_pop_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_var_pop_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_var_samp_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_var_samp_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "sale_variance_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "sale_variance_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "search_artifact_args": {
            "query": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "search_collectible_args": {
            "query": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "search_tezotop_args": {
            "query": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "search_ticket_args": {
            "query": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "search_token_args": {
            "query": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "search_unit_args": {
            "query": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact": {
            "enr": [
                3
            ],
            "id": [
                3
            ],
            "ipfs": [
                367
            ],
            "ipfsId": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "priceEnr": [
                515
            ],
            "priceMch": [
                515
            ],
            "priceMin": [
                515
            ],
            "priceUno": [
                515
            ],
            "priceXtz": [
                515
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_aggregate": {
            "aggregate": [
                677
            ],
            "nodes": [
                675
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_aggregate_fields": {
            "avg": [
                679
            ],
            "count": [
                3,
                {
                    "columns": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                682
            ],
            "min": [
                684
            ],
            "stddev": [
                688
            ],
            "stddev_pop": [
                690
            ],
            "stddev_samp": [
                692
            ],
            "sum": [
                694
            ],
            "var_pop": [
                696
            ],
            "var_samp": [
                698
            ],
            "variance": [
                700
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_aggregate_order_by": {
            "avg": [
                680
            ],
            "count": [
                544
            ],
            "max": [
                683
            ],
            "min": [
                685
            ],
            "stddev": [
                689
            ],
            "stddev_pop": [
                691
            ],
            "stddev_samp": [
                693
            ],
            "sum": [
                695
            ],
            "var_pop": [
                697
            ],
            "var_samp": [
                699
            ],
            "variance": [
                701
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_avg_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_avg_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_bool_exp": {
            "_and": [
                681
            ],
            "_not": [
                681
            ],
            "_or": [
                681
            ],
            "enr": [
                4
            ],
            "id": [
                4
            ],
            "ipfs": [
                371
            ],
            "ipfsId": [
                4
            ],
            "mch": [
                4
            ],
            "min": [
                4
            ],
            "priceEnr": [
                516
            ],
            "priceMch": [
                516
            ],
            "priceMin": [
                516
            ],
            "priceUno": [
                516
            ],
            "priceXtz": [
                516
            ],
            "uno": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_max_fields": {
            "enr": [
                3
            ],
            "id": [
                3
            ],
            "ipfsId": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "priceEnr": [
                515
            ],
            "priceMch": [
                515
            ],
            "priceMin": [
                515
            ],
            "priceUno": [
                515
            ],
            "priceXtz": [
                515
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_max_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_min_fields": {
            "enr": [
                3
            ],
            "id": [
                3
            ],
            "ipfsId": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "priceEnr": [
                515
            ],
            "priceMch": [
                515
            ],
            "priceMin": [
                515
            ],
            "priceUno": [
                515
            ],
            "priceXtz": [
                515
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_min_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfs": [
                374
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_select_column": {},
        "stock_artifact_stddev_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_stddev_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_stddev_pop_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_stddev_pop_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_stddev_samp_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_stddev_samp_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_sum_fields": {
            "enr": [
                3
            ],
            "id": [
                3
            ],
            "ipfsId": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "priceEnr": [
                515
            ],
            "priceMch": [
                515
            ],
            "priceMin": [
                515
            ],
            "priceUno": [
                515
            ],
            "priceXtz": [
                515
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_sum_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_var_pop_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_var_pop_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_var_samp_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_var_samp_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_variance_fields": {
            "enr": [
                2
            ],
            "id": [
                2
            ],
            "ipfsId": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "priceEnr": [
                2
            ],
            "priceMch": [
                2
            ],
            "priceMin": [
                2
            ],
            "priceUno": [
                2
            ],
            "priceXtz": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "stock_artifact_variance_order_by": {
            "enr": [
                544
            ],
            "id": [
                544
            ],
            "ipfsId": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "priceEnr": [
                544
            ],
            "priceMch": [
                544
            ],
            "priceMin": [
                544
            ],
            "priceUno": [
                544
            ],
            "priceXtz": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop": {
            "buildings": [
                120,
                {
                    "distinct_on": [
                        132,
                        "[building_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        131,
                        "[building_order_by!]"
                    ],
                    "where": [
                        126
                    ]
                }
            ],
            "buildings_aggregate": [
                121,
                {
                    "distinct_on": [
                        132,
                        "[building_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        131,
                        "[building_order_by!]"
                    ],
                    "where": [
                        126
                    ]
                }
            ],
            "coordinateX": [
                3
            ],
            "coordinateY": [
                3
            ],
            "coordinateZ": [
                3
            ],
            "enr": [
                3
            ],
            "lastClaimTime": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "name": [
                5
            ],
            "tezotopian": [
                0
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_aggregate": {
            "aggregate": [
                704
            ],
            "nodes": [
                702
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_aggregate_fields": {
            "avg": [
                706
            ],
            "count": [
                3,
                {
                    "columns": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                709
            ],
            "min": [
                711
            ],
            "stddev": [
                715
            ],
            "stddev_pop": [
                717
            ],
            "stddev_samp": [
                719
            ],
            "sum": [
                721
            ],
            "var_pop": [
                723
            ],
            "var_samp": [
                725
            ],
            "variance": [
                727
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_aggregate_order_by": {
            "avg": [
                707
            ],
            "count": [
                544
            ],
            "max": [
                710
            ],
            "min": [
                712
            ],
            "stddev": [
                716
            ],
            "stddev_pop": [
                718
            ],
            "stddev_samp": [
                720
            ],
            "sum": [
                722
            ],
            "var_pop": [
                724
            ],
            "var_samp": [
                726
            ],
            "variance": [
                728
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_avg_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_avg_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_bool_exp": {
            "_and": [
                708
            ],
            "_not": [
                708
            ],
            "_or": [
                708
            ],
            "buildings": [
                126
            ],
            "coordinateX": [
                4
            ],
            "coordinateY": [
                4
            ],
            "coordinateZ": [
                4
            ],
            "enr": [
                4
            ],
            "lastClaimTime": [
                4
            ],
            "mch": [
                4
            ],
            "min": [
                4
            ],
            "name": [
                6
            ],
            "tezotopian": [
                1
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "uid": [
                4
            ],
            "uno": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_max_fields": {
            "coordinateX": [
                3
            ],
            "coordinateY": [
                3
            ],
            "coordinateZ": [
                3
            ],
            "enr": [
                3
            ],
            "lastClaimTime": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_max_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_min_fields": {
            "coordinateX": [
                3
            ],
            "coordinateY": [
                3
            ],
            "coordinateZ": [
                3
            ],
            "enr": [
                3
            ],
            "lastClaimTime": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_min_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_order_by": {
            "buildings_aggregate": [
                123
            ],
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "name": [
                544
            ],
            "tezotopian": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_select_column": {},
        "tezotop_stddev_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_stddev_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_stddev_pop_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_stddev_pop_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_stddev_samp_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_stddev_samp_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_sum_fields": {
            "coordinateX": [
                3
            ],
            "coordinateY": [
                3
            ],
            "coordinateZ": [
                3
            ],
            "enr": [
                3
            ],
            "lastClaimTime": [
                3
            ],
            "mch": [
                3
            ],
            "min": [
                3
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "uno": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_sum_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_var_pop_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_var_pop_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_var_samp_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_var_samp_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_variance_fields": {
            "coordinateX": [
                2
            ],
            "coordinateY": [
                2
            ],
            "coordinateZ": [
                2
            ],
            "enr": [
                2
            ],
            "lastClaimTime": [
                2
            ],
            "mch": [
                2
            ],
            "min": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "uno": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "tezotop_variance_order_by": {
            "coordinateX": [
                544
            ],
            "coordinateY": [
                544
            ],
            "coordinateZ": [
                544
            ],
            "enr": [
                544
            ],
            "lastClaimTime": [
                544
            ],
            "mch": [
                544
            ],
            "min": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "uno": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket": {
            "name": [
                5
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "ticket_aggregate": {
            "aggregate": [
                731
            ],
            "nodes": [
                729
            ],
            "__typename": [
                5
            ]
        },
        "ticket_aggregate_fields": {
            "avg": [
                733
            ],
            "count": [
                3,
                {
                    "columns": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                763
            ],
            "min": [
                765
            ],
            "stddev": [
                769
            ],
            "stddev_pop": [
                771
            ],
            "stddev_samp": [
                773
            ],
            "sum": [
                775
            ],
            "var_pop": [
                777
            ],
            "var_samp": [
                779
            ],
            "variance": [
                781
            ],
            "__typename": [
                5
            ]
        },
        "ticket_aggregate_order_by": {
            "avg": [
                734
            ],
            "count": [
                544
            ],
            "max": [
                764
            ],
            "min": [
                766
            ],
            "stddev": [
                770
            ],
            "stddev_pop": [
                772
            ],
            "stddev_samp": [
                774
            ],
            "sum": [
                776
            ],
            "var_pop": [
                778
            ],
            "var_samp": [
                780
            ],
            "variance": [
                782
            ],
            "__typename": [
                5
            ]
        },
        "ticket_avg_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_avg_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_bool_exp": {
            "_and": [
                735
            ],
            "_not": [
                735
            ],
            "_or": [
                735
            ],
            "name": [
                6
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "uid": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger": {
            "id": [
                3
            ],
            "ticketCount": [
                3
            ],
            "user": [
                866
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_aggregate": {
            "aggregate": [
                738
            ],
            "nodes": [
                736
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_aggregate_fields": {
            "avg": [
                740
            ],
            "count": [
                3,
                {
                    "columns": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                743
            ],
            "min": [
                745
            ],
            "stddev": [
                749
            ],
            "stddev_pop": [
                751
            ],
            "stddev_samp": [
                753
            ],
            "sum": [
                755
            ],
            "var_pop": [
                757
            ],
            "var_samp": [
                759
            ],
            "variance": [
                761
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_aggregate_order_by": {
            "avg": [
                741
            ],
            "count": [
                544
            ],
            "max": [
                744
            ],
            "min": [
                746
            ],
            "stddev": [
                750
            ],
            "stddev_pop": [
                752
            ],
            "stddev_samp": [
                754
            ],
            "sum": [
                756
            ],
            "var_pop": [
                758
            ],
            "var_samp": [
                760
            ],
            "variance": [
                762
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_avg_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_avg_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_bool_exp": {
            "_and": [
                742
            ],
            "_not": [
                742
            ],
            "_or": [
                742
            ],
            "id": [
                4
            ],
            "ticketCount": [
                4
            ],
            "user": [
                869
            ],
            "userId": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_max_fields": {
            "id": [
                3
            ],
            "ticketCount": [
                3
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_max_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_min_fields": {
            "id": [
                3
            ],
            "ticketCount": [
                3
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_min_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "user": [
                872
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_select_column": {},
        "ticket_ledger_stddev_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_stddev_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_stddev_pop_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_stddev_pop_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_stddev_samp_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_stddev_samp_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_sum_fields": {
            "id": [
                3
            ],
            "ticketCount": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_sum_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_var_pop_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_var_pop_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_var_samp_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_var_samp_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_variance_fields": {
            "id": [
                2
            ],
            "ticketCount": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_ledger_variance_order_by": {
            "id": [
                544
            ],
            "ticketCount": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_max_fields": {
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "ticket_max_order_by": {
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_min_fields": {
            "name": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "ticket_min_order_by": {
            "name": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_order_by": {
            "name": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_select_column": {},
        "ticket_stddev_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_stddev_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_stddev_pop_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_stddev_pop_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_stddev_samp_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_stddev_samp_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_sum_fields": {
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "ticket_sum_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_var_pop_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_var_pop_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_var_samp_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_var_samp_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "ticket_variance_fields": {
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "ticket_variance_order_by": {
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "timestamptz": {},
        "timestamptz_comparison_exp": {
            "_eq": [
                783
            ],
            "_gt": [
                783
            ],
            "_gte": [
                783
            ],
            "_in": [
                783
            ],
            "_is_null": [
                0
            ],
            "_lt": [
                783
            ],
            "_lte": [
                783
            ],
            "_neq": [
                783
            ],
            "_nin": [
                783
            ],
            "__typename": [
                5
            ]
        },
        "token": {
            "artifacts": [
                7,
                {
                    "distinct_on": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        18,
                        "[artifact_order_by!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "artifacts_aggregate": [
                8,
                {
                    "distinct_on": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        18,
                        "[artifact_order_by!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "category": [
                5
            ],
            "collectibles": [
                147,
                {
                    "distinct_on": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        158,
                        "[collectible_order_by!]"
                    ],
                    "where": [
                        153
                    ]
                }
            ],
            "collectibles_aggregate": [
                148,
                {
                    "distinct_on": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        158,
                        "[collectible_order_by!]"
                    ],
                    "where": [
                        153
                    ]
                }
            ],
            "creator": [
                866
            ],
            "creatorId": [
                5
            ],
            "ipfs": [
                367
            ],
            "ipfsId": [
                3
            ],
            "marketplace": [
                483
            ],
            "marketplaceId": [
                3
            ],
            "mintTime": [
                783
            ],
            "name": [
                5
            ],
            "operations": [
                517,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "operations_aggregate": [
                518,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "owner": [
                866
            ],
            "ownerId": [
                5
            ],
            "primarySales": [
                545,
                {
                    "distinct_on": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        572,
                        "[primary_sale_order_by!]"
                    ],
                    "where": [
                        551
                    ]
                }
            ],
            "primarySales_aggregate": [
                546,
                {
                    "distinct_on": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        572,
                        "[primary_sale_order_by!]"
                    ],
                    "where": [
                        551
                    ]
                }
            ],
            "raffleTokens": [
                600,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffleTokens_aggregate": [
                601,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffles": [
                588,
                {
                    "distinct_on": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        599,
                        "[raffle_order_by!]"
                    ],
                    "where": [
                        594
                    ]
                }
            ],
            "raffles_aggregate": [
                589,
                {
                    "distinct_on": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        599,
                        "[raffle_order_by!]"
                    ],
                    "where": [
                        594
                    ]
                }
            ],
            "sales": [
                642,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "sales_aggregate": [
                643,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "tezotops": [
                702,
                {
                    "distinct_on": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        713,
                        "[tezotop_order_by!]"
                    ],
                    "where": [
                        708
                    ]
                }
            ],
            "tezotops_aggregate": [
                703,
                {
                    "distinct_on": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        713,
                        "[tezotop_order_by!]"
                    ],
                    "where": [
                        708
                    ]
                }
            ],
            "tickets": [
                729,
                {
                    "distinct_on": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        767,
                        "[ticket_order_by!]"
                    ],
                    "where": [
                        735
                    ]
                }
            ],
            "tickets_aggregate": [
                730,
                {
                    "distinct_on": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        767,
                        "[ticket_order_by!]"
                    ],
                    "where": [
                        735
                    ]
                }
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "units": [
                839,
                {
                    "distinct_on": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        850,
                        "[unit_order_by!]"
                    ],
                    "where": [
                        845
                    ]
                }
            ],
            "units_aggregate": [
                840,
                {
                    "distinct_on": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        850,
                        "[unit_order_by!]"
                    ],
                    "where": [
                        845
                    ]
                }
            ],
            "__typename": [
                5
            ]
        },
        "token_aggregate": {
            "aggregate": [
                787
            ],
            "nodes": [
                785
            ],
            "__typename": [
                5
            ]
        },
        "token_aggregate_fields": {
            "avg": [
                789
            ],
            "count": [
                3,
                {
                    "columns": [
                        797,
                        "[token_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                792
            ],
            "min": [
                794
            ],
            "stddev": [
                798
            ],
            "stddev_pop": [
                800
            ],
            "stddev_samp": [
                802
            ],
            "sum": [
                804
            ],
            "var_pop": [
                806
            ],
            "var_samp": [
                808
            ],
            "variance": [
                810
            ],
            "__typename": [
                5
            ]
        },
        "token_aggregate_order_by": {
            "avg": [
                790
            ],
            "count": [
                544
            ],
            "max": [
                793
            ],
            "min": [
                795
            ],
            "stddev": [
                799
            ],
            "stddev_pop": [
                801
            ],
            "stddev_samp": [
                803
            ],
            "sum": [
                805
            ],
            "var_pop": [
                807
            ],
            "var_samp": [
                809
            ],
            "variance": [
                811
            ],
            "__typename": [
                5
            ]
        },
        "token_avg_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_avg_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_bool_exp": {
            "_and": [
                791
            ],
            "_not": [
                791
            ],
            "_or": [
                791
            ],
            "artifacts": [
                13
            ],
            "category": [
                6
            ],
            "collectibles": [
                153
            ],
            "creator": [
                869
            ],
            "creatorId": [
                6
            ],
            "ipfs": [
                371
            ],
            "ipfsId": [
                4
            ],
            "marketplace": [
                487
            ],
            "marketplaceId": [
                4
            ],
            "mintTime": [
                784
            ],
            "name": [
                6
            ],
            "operations": [
                523
            ],
            "owner": [
                869
            ],
            "ownerId": [
                6
            ],
            "primarySales": [
                551
            ],
            "raffleTokens": [
                606
            ],
            "raffles": [
                594
            ],
            "sales": [
                648
            ],
            "tezotops": [
                708
            ],
            "tickets": [
                735
            ],
            "tokenId": [
                4
            ],
            "uid": [
                4
            ],
            "units": [
                845
            ],
            "__typename": [
                5
            ]
        },
        "token_max_fields": {
            "category": [
                5
            ],
            "creatorId": [
                5
            ],
            "ipfsId": [
                3
            ],
            "marketplaceId": [
                3
            ],
            "mintTime": [
                783
            ],
            "name": [
                5
            ],
            "ownerId": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "token_max_order_by": {
            "category": [
                544
            ],
            "creatorId": [
                544
            ],
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "mintTime": [
                544
            ],
            "name": [
                544
            ],
            "ownerId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_min_fields": {
            "category": [
                5
            ],
            "creatorId": [
                5
            ],
            "ipfsId": [
                3
            ],
            "marketplaceId": [
                3
            ],
            "mintTime": [
                783
            ],
            "name": [
                5
            ],
            "ownerId": [
                5
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "token_min_order_by": {
            "category": [
                544
            ],
            "creatorId": [
                544
            ],
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "mintTime": [
                544
            ],
            "name": [
                544
            ],
            "ownerId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_order_by": {
            "artifacts_aggregate": [
                10
            ],
            "category": [
                544
            ],
            "collectibles_aggregate": [
                150
            ],
            "creator": [
                872
            ],
            "creatorId": [
                544
            ],
            "ipfs": [
                374
            ],
            "ipfsId": [
                544
            ],
            "marketplace": [
                490
            ],
            "marketplaceId": [
                544
            ],
            "mintTime": [
                544
            ],
            "name": [
                544
            ],
            "operations_aggregate": [
                520
            ],
            "owner": [
                872
            ],
            "ownerId": [
                544
            ],
            "primarySales_aggregate": [
                548
            ],
            "raffleTokens_aggregate": [
                603
            ],
            "raffles_aggregate": [
                591
            ],
            "sales_aggregate": [
                645
            ],
            "tezotops_aggregate": [
                705
            ],
            "tickets_aggregate": [
                732
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "units_aggregate": [
                842
            ],
            "__typename": [
                5
            ]
        },
        "token_select_column": {},
        "token_stddev_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_stddev_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_stddev_pop_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_stddev_pop_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_stddev_samp_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_stddev_samp_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_sum_fields": {
            "ipfsId": [
                3
            ],
            "marketplaceId": [
                3
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "token_sum_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_var_pop_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_var_pop_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_var_samp_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_var_samp_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "token_variance_fields": {
            "ipfsId": [
                2
            ],
            "marketplaceId": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "token_variance_order_by": {
            "ipfsId": [
                544
            ],
            "marketplaceId": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer": {
            "amount": [
                515
            ],
            "id": [
                3
            ],
            "user": [
                866
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_aggregate": {
            "aggregate": [
                814
            ],
            "nodes": [
                812
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_aggregate_fields": {
            "avg": [
                816
            ],
            "count": [
                3,
                {
                    "columns": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                819
            ],
            "min": [
                821
            ],
            "stddev": [
                825
            ],
            "stddev_pop": [
                827
            ],
            "stddev_samp": [
                829
            ],
            "sum": [
                831
            ],
            "var_pop": [
                833
            ],
            "var_samp": [
                835
            ],
            "variance": [
                837
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_aggregate_order_by": {
            "avg": [
                817
            ],
            "count": [
                544
            ],
            "max": [
                820
            ],
            "min": [
                822
            ],
            "stddev": [
                826
            ],
            "stddev_pop": [
                828
            ],
            "stddev_samp": [
                830
            ],
            "sum": [
                832
            ],
            "var_pop": [
                834
            ],
            "var_samp": [
                836
            ],
            "variance": [
                838
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_avg_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_avg_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_bool_exp": {
            "_and": [
                818
            ],
            "_not": [
                818
            ],
            "_or": [
                818
            ],
            "amount": [
                516
            ],
            "id": [
                4
            ],
            "user": [
                869
            ],
            "userId": [
                6
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_max_fields": {
            "amount": [
                515
            ],
            "id": [
                3
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_max_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_min_fields": {
            "amount": [
                515
            ],
            "id": [
                3
            ],
            "userId": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_min_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "user": [
                872
            ],
            "userId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_select_column": {},
        "top_buyer_stddev_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_stddev_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_stddev_pop_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_stddev_pop_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_stddev_samp_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_stddev_samp_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_sum_fields": {
            "amount": [
                515
            ],
            "id": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_sum_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_var_pop_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_var_pop_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_var_samp_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_var_samp_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_variance_fields": {
            "amount": [
                2
            ],
            "id": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "top_buyer_variance_order_by": {
            "amount": [
                544
            ],
            "id": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit": {
            "accuracy": [
                3
            ],
            "attack": [
                3
            ],
            "damage": [
                3
            ],
            "damageTime": [
                783
            ],
            "defence": [
                3
            ],
            "exp": [
                3
            ],
            "hp": [
                3
            ],
            "name": [
                5
            ],
            "plays": [
                3
            ],
            "stealth": [
                3
            ],
            "tech": [
                3
            ],
            "token": [
                785
            ],
            "tokenId": [
                3
            ],
            "type": [
                5
            ],
            "uid": [
                3
            ],
            "unitBattles": [
                87,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "unitBattles_aggregate": [
                88,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "wave": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "unit_aggregate": {
            "aggregate": [
                841
            ],
            "nodes": [
                839
            ],
            "__typename": [
                5
            ]
        },
        "unit_aggregate_fields": {
            "avg": [
                843
            ],
            "count": [
                3,
                {
                    "columns": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                846
            ],
            "min": [
                848
            ],
            "stddev": [
                852
            ],
            "stddev_pop": [
                854
            ],
            "stddev_samp": [
                856
            ],
            "sum": [
                858
            ],
            "var_pop": [
                860
            ],
            "var_samp": [
                862
            ],
            "variance": [
                864
            ],
            "__typename": [
                5
            ]
        },
        "unit_aggregate_order_by": {
            "avg": [
                844
            ],
            "count": [
                544
            ],
            "max": [
                847
            ],
            "min": [
                849
            ],
            "stddev": [
                853
            ],
            "stddev_pop": [
                855
            ],
            "stddev_samp": [
                857
            ],
            "sum": [
                859
            ],
            "var_pop": [
                861
            ],
            "var_samp": [
                863
            ],
            "variance": [
                865
            ],
            "__typename": [
                5
            ]
        },
        "unit_avg_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_avg_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_bool_exp": {
            "_and": [
                845
            ],
            "_not": [
                845
            ],
            "_or": [
                845
            ],
            "accuracy": [
                4
            ],
            "attack": [
                4
            ],
            "damage": [
                4
            ],
            "damageTime": [
                784
            ],
            "defence": [
                4
            ],
            "exp": [
                4
            ],
            "hp": [
                4
            ],
            "name": [
                6
            ],
            "plays": [
                4
            ],
            "stealth": [
                4
            ],
            "tech": [
                4
            ],
            "token": [
                791
            ],
            "tokenId": [
                4
            ],
            "type": [
                6
            ],
            "uid": [
                4
            ],
            "unitBattles": [
                93
            ],
            "wave": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "unit_max_fields": {
            "accuracy": [
                3
            ],
            "attack": [
                3
            ],
            "damage": [
                3
            ],
            "damageTime": [
                783
            ],
            "defence": [
                3
            ],
            "exp": [
                3
            ],
            "hp": [
                3
            ],
            "name": [
                5
            ],
            "plays": [
                3
            ],
            "stealth": [
                3
            ],
            "tech": [
                3
            ],
            "tokenId": [
                3
            ],
            "type": [
                5
            ],
            "uid": [
                3
            ],
            "wave": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "unit_max_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "damageTime": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "name": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "type": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_min_fields": {
            "accuracy": [
                3
            ],
            "attack": [
                3
            ],
            "damage": [
                3
            ],
            "damageTime": [
                783
            ],
            "defence": [
                3
            ],
            "exp": [
                3
            ],
            "hp": [
                3
            ],
            "name": [
                5
            ],
            "plays": [
                3
            ],
            "stealth": [
                3
            ],
            "tech": [
                3
            ],
            "tokenId": [
                3
            ],
            "type": [
                5
            ],
            "uid": [
                3
            ],
            "wave": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "unit_min_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "damageTime": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "name": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "type": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "damageTime": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "name": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "token": [
                796
            ],
            "tokenId": [
                544
            ],
            "type": [
                544
            ],
            "uid": [
                544
            ],
            "unitBattles_aggregate": [
                90
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_select_column": {},
        "unit_stddev_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_stddev_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_stddev_pop_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_stddev_pop_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_stddev_samp_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_stddev_samp_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_sum_fields": {
            "accuracy": [
                3
            ],
            "attack": [
                3
            ],
            "damage": [
                3
            ],
            "defence": [
                3
            ],
            "exp": [
                3
            ],
            "hp": [
                3
            ],
            "plays": [
                3
            ],
            "stealth": [
                3
            ],
            "tech": [
                3
            ],
            "tokenId": [
                3
            ],
            "uid": [
                3
            ],
            "wave": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "unit_sum_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_var_pop_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_var_pop_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_var_samp_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_var_samp_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "unit_variance_fields": {
            "accuracy": [
                2
            ],
            "attack": [
                2
            ],
            "damage": [
                2
            ],
            "defence": [
                2
            ],
            "exp": [
                2
            ],
            "hp": [
                2
            ],
            "plays": [
                2
            ],
            "stealth": [
                2
            ],
            "tech": [
                2
            ],
            "tokenId": [
                2
            ],
            "uid": [
                2
            ],
            "wave": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "unit_variance_order_by": {
            "accuracy": [
                544
            ],
            "attack": [
                544
            ],
            "damage": [
                544
            ],
            "defence": [
                544
            ],
            "exp": [
                544
            ],
            "hp": [
                544
            ],
            "plays": [
                544
            ],
            "stealth": [
                544
            ],
            "tech": [
                544
            ],
            "tokenId": [
                544
            ],
            "uid": [
                544
            ],
            "wave": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "user": {
            "acceptedChallenges": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "acceptedChallenges_aggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "address": [
                5
            ],
            "battleLocks": [
                34,
                {
                    "distinct_on": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        77,
                        "[battle_order_by!]"
                    ],
                    "where": [
                        40
                    ]
                }
            ],
            "battleLocks_aggregate": [
                35,
                {
                    "distinct_on": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        77,
                        "[battle_order_by!]"
                    ],
                    "where": [
                        40
                    ]
                }
            ],
            "createdChallenges": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "createdChallenges_aggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "createdDiplomats": [
                278,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "createdDiplomats_aggregate": [
                279,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "createdTokens": [
                785,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "createdTokens_aggregate": [
                786,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "diplomats": [
                278,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "diplomats_aggregate": [
                279,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "fromOperations": [
                517,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "fromOperations_aggregate": [
                518,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "lockedResources": [
                386,
                {
                    "distinct_on": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        397,
                        "[locked_resource_order_by!]"
                    ],
                    "where": [
                        392
                    ]
                }
            ],
            "lockedResources_aggregate": [
                387,
                {
                    "distinct_on": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        397,
                        "[locked_resource_order_by!]"
                    ],
                    "where": [
                        392
                    ]
                }
            ],
            "lotteryParticipants": [
                448,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "lotteryParticipants_aggregate": [
                449,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "purchases": [
                642,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "purchases_aggregate": [
                643,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "raffleParticipants": [
                600,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffleParticipants_aggregate": [
                601,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "sales": [
                642,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "sales_aggregate": [
                643,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "ticketLedgers": [
                736,
                {
                    "distinct_on": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        747,
                        "[ticket_ledger_order_by!]"
                    ],
                    "where": [
                        742
                    ]
                }
            ],
            "ticketLedgers_aggregate": [
                737,
                {
                    "distinct_on": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        747,
                        "[ticket_ledger_order_by!]"
                    ],
                    "where": [
                        742
                    ]
                }
            ],
            "toOperations": [
                517,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "toOperations_aggregate": [
                518,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "tokens": [
                785,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokens_aggregate": [
                786,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "topBuyers": [
                812,
                {
                    "distinct_on": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        823,
                        "[top_buyer_order_by!]"
                    ],
                    "where": [
                        818
                    ]
                }
            ],
            "topBuyers_aggregate": [
                813,
                {
                    "distinct_on": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        823,
                        "[top_buyer_order_by!]"
                    ],
                    "where": [
                        818
                    ]
                }
            ],
            "username": [
                5
            ],
            "wonChallenges": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "wonChallenges_aggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "__typename": [
                5
            ]
        },
        "user_aggregate": {
            "aggregate": [
                868
            ],
            "nodes": [
                866
            ],
            "__typename": [
                5
            ]
        },
        "user_aggregate_fields": {
            "count": [
                3,
                {
                    "columns": [
                        873,
                        "[user_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                870
            ],
            "min": [
                871
            ],
            "__typename": [
                5
            ]
        },
        "user_bool_exp": {
            "_and": [
                869
            ],
            "_not": [
                869
            ],
            "_or": [
                869
            ],
            "acceptedChallenges": [
                319
            ],
            "address": [
                6
            ],
            "battleLocks": [
                40
            ],
            "createdChallenges": [
                319
            ],
            "createdDiplomats": [
                284
            ],
            "createdTokens": [
                791
            ],
            "diplomats": [
                284
            ],
            "fromOperations": [
                523
            ],
            "lockedResources": [
                392
            ],
            "lotteryParticipants": [
                454
            ],
            "purchases": [
                648
            ],
            "raffleParticipants": [
                606
            ],
            "sales": [
                648
            ],
            "ticketLedgers": [
                742
            ],
            "toOperations": [
                523
            ],
            "tokens": [
                791
            ],
            "topBuyers": [
                818
            ],
            "username": [
                6
            ],
            "wonChallenges": [
                319
            ],
            "__typename": [
                5
            ]
        },
        "user_max_fields": {
            "address": [
                5
            ],
            "username": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "user_min_fields": {
            "address": [
                5
            ],
            "username": [
                5
            ],
            "__typename": [
                5
            ]
        },
        "user_order_by": {
            "acceptedChallenges_aggregate": [
                316
            ],
            "address": [
                544
            ],
            "battleLocks_aggregate": [
                37
            ],
            "createdChallenges_aggregate": [
                316
            ],
            "createdDiplomats_aggregate": [
                281
            ],
            "createdTokens_aggregate": [
                788
            ],
            "diplomats_aggregate": [
                281
            ],
            "fromOperations_aggregate": [
                520
            ],
            "lockedResources_aggregate": [
                389
            ],
            "lotteryParticipants_aggregate": [
                451
            ],
            "purchases_aggregate": [
                645
            ],
            "raffleParticipants_aggregate": [
                603
            ],
            "sales_aggregate": [
                645
            ],
            "ticketLedgers_aggregate": [
                739
            ],
            "toOperations_aggregate": [
                520
            ],
            "tokens_aggregate": [
                788
            ],
            "topBuyers_aggregate": [
                815
            ],
            "username": [
                544
            ],
            "wonChallenges_aggregate": [
                316
            ],
            "__typename": [
                5
            ]
        },
        "user_select_column": {},
        "whitelisted_token": {
            "contractAddress": [
                5
            ],
            "contractId": [
                3
            ],
            "decimals": [
                3
            ],
            "id": [
                3
            ],
            "lockedTokens": [
                413,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "lockedTokens_aggregate": [
                414,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "name": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_aggregate": {
            "aggregate": [
                876
            ],
            "nodes": [
                874
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_aggregate_fields": {
            "avg": [
                877
            ],
            "count": [
                3,
                {
                    "columns": [
                        882,
                        "[whitelisted_token_select_column!]"
                    ],
                    "distinct": [
                        0
                    ]
                }
            ],
            "max": [
                879
            ],
            "min": [
                880
            ],
            "stddev": [
                883
            ],
            "stddev_pop": [
                884
            ],
            "stddev_samp": [
                885
            ],
            "sum": [
                886
            ],
            "var_pop": [
                887
            ],
            "var_samp": [
                888
            ],
            "variance": [
                889
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_avg_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_bool_exp": {
            "_and": [
                878
            ],
            "_not": [
                878
            ],
            "_or": [
                878
            ],
            "contractAddress": [
                6
            ],
            "contractId": [
                4
            ],
            "decimals": [
                4
            ],
            "id": [
                4
            ],
            "lockedTokens": [
                419
            ],
            "name": [
                6
            ],
            "symbol": [
                6
            ],
            "thumbnail": [
                6
            ],
            "tokenId": [
                4
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_max_fields": {
            "contractAddress": [
                5
            ],
            "contractId": [
                3
            ],
            "decimals": [
                3
            ],
            "id": [
                3
            ],
            "name": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_min_fields": {
            "contractAddress": [
                5
            ],
            "contractId": [
                3
            ],
            "decimals": [
                3
            ],
            "id": [
                3
            ],
            "name": [
                5
            ],
            "symbol": [
                5
            ],
            "thumbnail": [
                5
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_order_by": {
            "contractAddress": [
                544
            ],
            "contractId": [
                544
            ],
            "decimals": [
                544
            ],
            "id": [
                544
            ],
            "lockedTokens_aggregate": [
                416
            ],
            "name": [
                544
            ],
            "symbol": [
                544
            ],
            "thumbnail": [
                544
            ],
            "tokenId": [
                544
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_select_column": {},
        "whitelisted_token_stddev_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_stddev_pop_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_stddev_samp_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_sum_fields": {
            "contractId": [
                3
            ],
            "decimals": [
                3
            ],
            "id": [
                3
            ],
            "tokenId": [
                3
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_var_pop_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_var_samp_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "whitelisted_token_variance_fields": {
            "contractId": [
                2
            ],
            "decimals": [
                2
            ],
            "id": [
                2
            ],
            "tokenId": [
                2
            ],
            "__typename": [
                5
            ]
        },
        "Query": {
            "artifact": [
                7,
                {
                    "distinct_on": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        18,
                        "[artifact_order_by!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "artifactAggregate": [
                8,
                {
                    "distinct_on": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        18,
                        "[artifact_order_by!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "artifactByPk": [
                7,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "battle": [
                34,
                {
                    "distinct_on": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        77,
                        "[battle_order_by!]"
                    ],
                    "where": [
                        40
                    ]
                }
            ],
            "battleAggregate": [
                35,
                {
                    "distinct_on": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        77,
                        "[battle_order_by!]"
                    ],
                    "where": [
                        40
                    ]
                }
            ],
            "battleByPk": [
                34,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "battleCounter": [
                41,
                {
                    "distinct_on": [
                        49,
                        "[battle_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        48,
                        "[battle_counter_order_by!]"
                    ],
                    "where": [
                        45
                    ]
                }
            ],
            "battleCounterAggregate": [
                42,
                {
                    "distinct_on": [
                        49,
                        "[battle_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        48,
                        "[battle_counter_order_by!]"
                    ],
                    "where": [
                        45
                    ]
                }
            ],
            "battleCounterByPk": [
                41,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "battleCrate": [
                57,
                {
                    "distinct_on": [
                        65,
                        "[battle_crate_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        64,
                        "[battle_crate_order_by!]"
                    ],
                    "where": [
                        61
                    ]
                }
            ],
            "battleCrateAggregate": [
                58,
                {
                    "distinct_on": [
                        65,
                        "[battle_crate_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        64,
                        "[battle_crate_order_by!]"
                    ],
                    "where": [
                        61
                    ]
                }
            ],
            "battleCrateByPk": [
                57,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "battleUnit": [
                87,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "battleUnitAggregate": [
                88,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "building": [
                120,
                {
                    "distinct_on": [
                        132,
                        "[building_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        131,
                        "[building_order_by!]"
                    ],
                    "where": [
                        126
                    ]
                }
            ],
            "buildingAggregate": [
                121,
                {
                    "distinct_on": [
                        132,
                        "[building_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        131,
                        "[building_order_by!]"
                    ],
                    "where": [
                        126
                    ]
                }
            ],
            "buildingByPk": [
                120,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "collectible": [
                147,
                {
                    "distinct_on": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        158,
                        "[collectible_order_by!]"
                    ],
                    "where": [
                        153
                    ]
                }
            ],
            "collectibleAggregate": [
                148,
                {
                    "distinct_on": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        158,
                        "[collectible_order_by!]"
                    ],
                    "where": [
                        153
                    ]
                }
            ],
            "collectibleByPk": [
                147,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "dipdupContract": [
                174,
                {
                    "distinct_on": [
                        197,
                        "[dipdup_contract_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        196,
                        "[dipdup_contract_order_by!]"
                    ],
                    "where": [
                        177
                    ]
                }
            ],
            "dipdupContractAggregate": [
                175,
                {
                    "distinct_on": [
                        197,
                        "[dipdup_contract_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        196,
                        "[dipdup_contract_order_by!]"
                    ],
                    "where": [
                        177
                    ]
                }
            ],
            "dipdupContractByPk": [
                174,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupContractMetadata": [
                179,
                {
                    "distinct_on": [
                        187,
                        "[dipdup_contract_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        186,
                        "[dipdup_contract_metadata_order_by!]"
                    ],
                    "where": [
                        183
                    ]
                }
            ],
            "dipdupContractMetadataAggregate": [
                180,
                {
                    "distinct_on": [
                        187,
                        "[dipdup_contract_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        186,
                        "[dipdup_contract_metadata_order_by!]"
                    ],
                    "where": [
                        183
                    ]
                }
            ],
            "dipdupContractMetadataByPk": [
                179,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "dipdupHead": [
                198,
                {
                    "distinct_on": [
                        206,
                        "[dipdup_head_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        205,
                        "[dipdup_head_order_by!]"
                    ],
                    "where": [
                        202
                    ]
                }
            ],
            "dipdupHeadAggregate": [
                199,
                {
                    "distinct_on": [
                        206,
                        "[dipdup_head_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        205,
                        "[dipdup_head_order_by!]"
                    ],
                    "where": [
                        202
                    ]
                }
            ],
            "dipdupHeadByPk": [
                198,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupHeadStatus": [
                207,
                {
                    "distinct_on": [
                        214,
                        "[dipdup_head_status_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        213,
                        "[dipdup_head_status_order_by!]"
                    ],
                    "where": [
                        210
                    ]
                }
            ],
            "dipdupHeadStatusAggregate": [
                208,
                {
                    "distinct_on": [
                        214,
                        "[dipdup_head_status_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        213,
                        "[dipdup_head_status_order_by!]"
                    ],
                    "where": [
                        210
                    ]
                }
            ],
            "dipdupIndex": [
                222,
                {
                    "distinct_on": [
                        230,
                        "[dipdup_index_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        229,
                        "[dipdup_index_order_by!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "dipdupIndexAggregate": [
                223,
                {
                    "distinct_on": [
                        230,
                        "[dipdup_index_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        229,
                        "[dipdup_index_order_by!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "dipdupIndexByPk": [
                222,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupModelUpdate": [
                238,
                {
                    "distinct_on": [
                        246,
                        "[dipdup_model_update_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        245,
                        "[dipdup_model_update_order_by!]"
                    ],
                    "where": [
                        242
                    ]
                }
            ],
            "dipdupModelUpdateAggregate": [
                239,
                {
                    "distinct_on": [
                        246,
                        "[dipdup_model_update_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        245,
                        "[dipdup_model_update_order_by!]"
                    ],
                    "where": [
                        242
                    ]
                }
            ],
            "dipdupModelUpdateByPk": [
                238,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "dipdupSchema": [
                254,
                {
                    "distinct_on": [
                        261,
                        "[dipdup_schema_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        260,
                        "[dipdup_schema_order_by!]"
                    ],
                    "where": [
                        257
                    ]
                }
            ],
            "dipdupSchemaAggregate": [
                255,
                {
                    "distinct_on": [
                        261,
                        "[dipdup_schema_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        260,
                        "[dipdup_schema_order_by!]"
                    ],
                    "where": [
                        257
                    ]
                }
            ],
            "dipdupSchemaByPk": [
                254,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupTokenMetadata": [
                262,
                {
                    "distinct_on": [
                        270,
                        "[dipdup_token_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        269,
                        "[dipdup_token_metadata_order_by!]"
                    ],
                    "where": [
                        266
                    ]
                }
            ],
            "dipdupTokenMetadataAggregate": [
                263,
                {
                    "distinct_on": [
                        270,
                        "[dipdup_token_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        269,
                        "[dipdup_token_metadata_order_by!]"
                    ],
                    "where": [
                        266
                    ]
                }
            ],
            "dipdupTokenMetadataByPk": [
                262,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "diplomat": [
                278,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "diplomatAggregate": [
                279,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "diplomatByPk": [
                278,
                {
                    "tokenId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "diplomatIpfs": [
                285,
                {
                    "distinct_on": [
                        292,
                        "[diplomat_ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        291,
                        "[diplomat_ipfs_order_by!]"
                    ],
                    "where": [
                        288
                    ]
                }
            ],
            "diplomatIpfsAggregate": [
                286,
                {
                    "distinct_on": [
                        292,
                        "[diplomat_ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        291,
                        "[diplomat_ipfs_order_by!]"
                    ],
                    "where": [
                        288
                    ]
                }
            ],
            "diplomatIpfsByPk": [
                285,
                {
                    "uri": [
                        5,
                        "String!"
                    ]
                }
            ],
            "directChallenge": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "directChallengeAggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "directChallengeByPk": [
                313,
                {
                    "challengeId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "faucetNft": [
                340,
                {
                    "distinct_on": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        351,
                        "[faucet_nft_order_by!]"
                    ],
                    "where": [
                        346
                    ]
                }
            ],
            "faucetNftAggregate": [
                341,
                {
                    "distinct_on": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        351,
                        "[faucet_nft_order_by!]"
                    ],
                    "where": [
                        346
                    ]
                }
            ],
            "faucetNftByPk": [
                340,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "ipfs": [
                367,
                {
                    "distinct_on": [
                        375,
                        "[ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        374,
                        "[ipfs_order_by!]"
                    ],
                    "where": [
                        371
                    ]
                }
            ],
            "ipfsAggregate": [
                368,
                {
                    "distinct_on": [
                        375,
                        "[ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        374,
                        "[ipfs_order_by!]"
                    ],
                    "where": [
                        371
                    ]
                }
            ],
            "ipfsByPk": [
                367,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lockedResource": [
                386,
                {
                    "distinct_on": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        397,
                        "[locked_resource_order_by!]"
                    ],
                    "where": [
                        392
                    ]
                }
            ],
            "lockedResourceAggregate": [
                387,
                {
                    "distinct_on": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        397,
                        "[locked_resource_order_by!]"
                    ],
                    "where": [
                        392
                    ]
                }
            ],
            "lockedResourceByPk": [
                386,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lockedToken": [
                413,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "lockedTokenAggregate": [
                414,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "lockedTokenByPk": [
                413,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lottery": [
                440,
                {
                    "distinct_on": [
                        475,
                        "[lottery_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        447,
                        "[lottery_order_by!]"
                    ],
                    "where": [
                        444
                    ]
                }
            ],
            "lotteryAggregate": [
                441,
                {
                    "distinct_on": [
                        475,
                        "[lottery_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        447,
                        "[lottery_order_by!]"
                    ],
                    "where": [
                        444
                    ]
                }
            ],
            "lotteryByPk": [
                440,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lotteryParticipant": [
                448,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "lotteryParticipantAggregate": [
                449,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "lotteryParticipantByPk": [
                448,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "marketplace": [
                483,
                {
                    "distinct_on": [
                        491,
                        "[marketplace_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        490,
                        "[marketplace_order_by!]"
                    ],
                    "where": [
                        487
                    ]
                }
            ],
            "marketplaceAggregate": [
                484,
                {
                    "distinct_on": [
                        491,
                        "[marketplace_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        490,
                        "[marketplace_order_by!]"
                    ],
                    "where": [
                        487
                    ]
                }
            ],
            "marketplaceByPk": [
                483,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "marketplaceStat": [
                492,
                {
                    "distinct_on": [
                        500,
                        "[marketplace_stat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        499,
                        "[marketplace_stat_order_by!]"
                    ],
                    "where": [
                        496
                    ]
                }
            ],
            "marketplaceStatAggregate": [
                493,
                {
                    "distinct_on": [
                        500,
                        "[marketplace_stat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        499,
                        "[marketplace_stat_order_by!]"
                    ],
                    "where": [
                        496
                    ]
                }
            ],
            "marketplaceStatByPk": [
                492,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "operation": [
                517,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "operationAggregate": [
                518,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "operationByPk": [
                517,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "primarySale": [
                545,
                {
                    "distinct_on": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        572,
                        "[primary_sale_order_by!]"
                    ],
                    "where": [
                        551
                    ]
                }
            ],
            "primarySaleAggregate": [
                546,
                {
                    "distinct_on": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        572,
                        "[primary_sale_order_by!]"
                    ],
                    "where": [
                        551
                    ]
                }
            ],
            "primarySaleByPk": [
                545,
                {
                    "listingId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "primarySaleCounter": [
                552,
                {
                    "distinct_on": [
                        560,
                        "[primary_sale_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        559,
                        "[primary_sale_counter_order_by!]"
                    ],
                    "where": [
                        556
                    ]
                }
            ],
            "primarySaleCounterAggregate": [
                553,
                {
                    "distinct_on": [
                        560,
                        "[primary_sale_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        559,
                        "[primary_sale_counter_order_by!]"
                    ],
                    "where": [
                        556
                    ]
                }
            ],
            "primarySaleCounterByPk": [
                552,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "raffle": [
                588,
                {
                    "distinct_on": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        599,
                        "[raffle_order_by!]"
                    ],
                    "where": [
                        594
                    ]
                }
            ],
            "raffleAggregate": [
                589,
                {
                    "distinct_on": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        599,
                        "[raffle_order_by!]"
                    ],
                    "where": [
                        594
                    ]
                }
            ],
            "raffleByPk": [
                588,
                {
                    "raffleId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "raffleParticipant": [
                600,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffleParticipantAggregate": [
                601,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffleParticipantByPk": [
                600,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "sale": [
                642,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "saleAggregate": [
                643,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "saleByPk": [
                642,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "search_artifact": [
                785,
                {
                    "args": [
                        669,
                        "search_artifact_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_artifact_aggregate": [
                786,
                {
                    "args": [
                        669,
                        "search_artifact_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_collectible": [
                785,
                {
                    "args": [
                        670,
                        "search_collectible_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_collectible_aggregate": [
                786,
                {
                    "args": [
                        670,
                        "search_collectible_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_tezotop": [
                785,
                {
                    "args": [
                        671,
                        "search_tezotop_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_tezotop_aggregate": [
                786,
                {
                    "args": [
                        671,
                        "search_tezotop_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_ticket": [
                785,
                {
                    "args": [
                        672,
                        "search_ticket_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_ticket_aggregate": [
                786,
                {
                    "args": [
                        672,
                        "search_ticket_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_token": [
                785,
                {
                    "args": [
                        673,
                        "search_token_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_token_aggregate": [
                786,
                {
                    "args": [
                        673,
                        "search_token_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_unit": [
                785,
                {
                    "args": [
                        674,
                        "search_unit_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_unit_aggregate": [
                786,
                {
                    "args": [
                        674,
                        "search_unit_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "stockArtifact": [
                675,
                {
                    "distinct_on": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        686,
                        "[stock_artifact_order_by!]"
                    ],
                    "where": [
                        681
                    ]
                }
            ],
            "stockArtifactAggregate": [
                676,
                {
                    "distinct_on": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        686,
                        "[stock_artifact_order_by!]"
                    ],
                    "where": [
                        681
                    ]
                }
            ],
            "stockArtifactByPk": [
                675,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "tezotop": [
                702,
                {
                    "distinct_on": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        713,
                        "[tezotop_order_by!]"
                    ],
                    "where": [
                        708
                    ]
                }
            ],
            "tezotopAggregate": [
                703,
                {
                    "distinct_on": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        713,
                        "[tezotop_order_by!]"
                    ],
                    "where": [
                        708
                    ]
                }
            ],
            "tezotopByPk": [
                702,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "ticket": [
                729,
                {
                    "distinct_on": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        767,
                        "[ticket_order_by!]"
                    ],
                    "where": [
                        735
                    ]
                }
            ],
            "ticketAggregate": [
                730,
                {
                    "distinct_on": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        767,
                        "[ticket_order_by!]"
                    ],
                    "where": [
                        735
                    ]
                }
            ],
            "ticketByPk": [
                729,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "ticketLedger": [
                736,
                {
                    "distinct_on": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        747,
                        "[ticket_ledger_order_by!]"
                    ],
                    "where": [
                        742
                    ]
                }
            ],
            "ticketLedgerAggregate": [
                737,
                {
                    "distinct_on": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        747,
                        "[ticket_ledger_order_by!]"
                    ],
                    "where": [
                        742
                    ]
                }
            ],
            "ticketLedgerByPk": [
                736,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "token": [
                785,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokenAggregate": [
                786,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokenByPk": [
                785,
                {
                    "tokenId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "topBuyer": [
                812,
                {
                    "distinct_on": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        823,
                        "[top_buyer_order_by!]"
                    ],
                    "where": [
                        818
                    ]
                }
            ],
            "topBuyerAggregate": [
                813,
                {
                    "distinct_on": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        823,
                        "[top_buyer_order_by!]"
                    ],
                    "where": [
                        818
                    ]
                }
            ],
            "topBuyerByPk": [
                812,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "unit": [
                839,
                {
                    "distinct_on": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        850,
                        "[unit_order_by!]"
                    ],
                    "where": [
                        845
                    ]
                }
            ],
            "unitAggregate": [
                840,
                {
                    "distinct_on": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        850,
                        "[unit_order_by!]"
                    ],
                    "where": [
                        845
                    ]
                }
            ],
            "unitByPk": [
                839,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "user": [
                866,
                {
                    "distinct_on": [
                        873,
                        "[user_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        872,
                        "[user_order_by!]"
                    ],
                    "where": [
                        869
                    ]
                }
            ],
            "userAggregate": [
                867,
                {
                    "distinct_on": [
                        873,
                        "[user_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        872,
                        "[user_order_by!]"
                    ],
                    "where": [
                        869
                    ]
                }
            ],
            "userByPk": [
                866,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "whitelistedToken": [
                874,
                {
                    "distinct_on": [
                        882,
                        "[whitelisted_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        881,
                        "[whitelisted_token_order_by!]"
                    ],
                    "where": [
                        878
                    ]
                }
            ],
            "whitelistedTokenAggregate": [
                875,
                {
                    "distinct_on": [
                        882,
                        "[whitelisted_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        881,
                        "[whitelisted_token_order_by!]"
                    ],
                    "where": [
                        878
                    ]
                }
            ],
            "whitelistedTokenByPk": [
                874,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "__typename": [
                5
            ]
        },
        "Subscription": {
            "artifact": [
                7,
                {
                    "distinct_on": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        18,
                        "[artifact_order_by!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "artifactAggregate": [
                8,
                {
                    "distinct_on": [
                        19,
                        "[artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        18,
                        "[artifact_order_by!]"
                    ],
                    "where": [
                        13
                    ]
                }
            ],
            "artifactByPk": [
                7,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "battle": [
                34,
                {
                    "distinct_on": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        77,
                        "[battle_order_by!]"
                    ],
                    "where": [
                        40
                    ]
                }
            ],
            "battleAggregate": [
                35,
                {
                    "distinct_on": [
                        78,
                        "[battle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        77,
                        "[battle_order_by!]"
                    ],
                    "where": [
                        40
                    ]
                }
            ],
            "battleByPk": [
                34,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "battleCounter": [
                41,
                {
                    "distinct_on": [
                        49,
                        "[battle_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        48,
                        "[battle_counter_order_by!]"
                    ],
                    "where": [
                        45
                    ]
                }
            ],
            "battleCounterAggregate": [
                42,
                {
                    "distinct_on": [
                        49,
                        "[battle_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        48,
                        "[battle_counter_order_by!]"
                    ],
                    "where": [
                        45
                    ]
                }
            ],
            "battleCounterByPk": [
                41,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "battleCrate": [
                57,
                {
                    "distinct_on": [
                        65,
                        "[battle_crate_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        64,
                        "[battle_crate_order_by!]"
                    ],
                    "where": [
                        61
                    ]
                }
            ],
            "battleCrateAggregate": [
                58,
                {
                    "distinct_on": [
                        65,
                        "[battle_crate_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        64,
                        "[battle_crate_order_by!]"
                    ],
                    "where": [
                        61
                    ]
                }
            ],
            "battleCrateByPk": [
                57,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "battleUnit": [
                87,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "battleUnitAggregate": [
                88,
                {
                    "distinct_on": [
                        99,
                        "[battle_unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        98,
                        "[battle_unit_order_by!]"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "building": [
                120,
                {
                    "distinct_on": [
                        132,
                        "[building_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        131,
                        "[building_order_by!]"
                    ],
                    "where": [
                        126
                    ]
                }
            ],
            "buildingAggregate": [
                121,
                {
                    "distinct_on": [
                        132,
                        "[building_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        131,
                        "[building_order_by!]"
                    ],
                    "where": [
                        126
                    ]
                }
            ],
            "buildingByPk": [
                120,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "collectible": [
                147,
                {
                    "distinct_on": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        158,
                        "[collectible_order_by!]"
                    ],
                    "where": [
                        153
                    ]
                }
            ],
            "collectibleAggregate": [
                148,
                {
                    "distinct_on": [
                        159,
                        "[collectible_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        158,
                        "[collectible_order_by!]"
                    ],
                    "where": [
                        153
                    ]
                }
            ],
            "collectibleByPk": [
                147,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "dipdupContract": [
                174,
                {
                    "distinct_on": [
                        197,
                        "[dipdup_contract_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        196,
                        "[dipdup_contract_order_by!]"
                    ],
                    "where": [
                        177
                    ]
                }
            ],
            "dipdupContractAggregate": [
                175,
                {
                    "distinct_on": [
                        197,
                        "[dipdup_contract_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        196,
                        "[dipdup_contract_order_by!]"
                    ],
                    "where": [
                        177
                    ]
                }
            ],
            "dipdupContractByPk": [
                174,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupContractMetadata": [
                179,
                {
                    "distinct_on": [
                        187,
                        "[dipdup_contract_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        186,
                        "[dipdup_contract_metadata_order_by!]"
                    ],
                    "where": [
                        183
                    ]
                }
            ],
            "dipdupContractMetadataAggregate": [
                180,
                {
                    "distinct_on": [
                        187,
                        "[dipdup_contract_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        186,
                        "[dipdup_contract_metadata_order_by!]"
                    ],
                    "where": [
                        183
                    ]
                }
            ],
            "dipdupContractMetadataByPk": [
                179,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "dipdupHead": [
                198,
                {
                    "distinct_on": [
                        206,
                        "[dipdup_head_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        205,
                        "[dipdup_head_order_by!]"
                    ],
                    "where": [
                        202
                    ]
                }
            ],
            "dipdupHeadAggregate": [
                199,
                {
                    "distinct_on": [
                        206,
                        "[dipdup_head_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        205,
                        "[dipdup_head_order_by!]"
                    ],
                    "where": [
                        202
                    ]
                }
            ],
            "dipdupHeadByPk": [
                198,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupHeadStatus": [
                207,
                {
                    "distinct_on": [
                        214,
                        "[dipdup_head_status_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        213,
                        "[dipdup_head_status_order_by!]"
                    ],
                    "where": [
                        210
                    ]
                }
            ],
            "dipdupHeadStatusAggregate": [
                208,
                {
                    "distinct_on": [
                        214,
                        "[dipdup_head_status_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        213,
                        "[dipdup_head_status_order_by!]"
                    ],
                    "where": [
                        210
                    ]
                }
            ],
            "dipdupIndex": [
                222,
                {
                    "distinct_on": [
                        230,
                        "[dipdup_index_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        229,
                        "[dipdup_index_order_by!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "dipdupIndexAggregate": [
                223,
                {
                    "distinct_on": [
                        230,
                        "[dipdup_index_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        229,
                        "[dipdup_index_order_by!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "dipdupIndexByPk": [
                222,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupModelUpdate": [
                238,
                {
                    "distinct_on": [
                        246,
                        "[dipdup_model_update_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        245,
                        "[dipdup_model_update_order_by!]"
                    ],
                    "where": [
                        242
                    ]
                }
            ],
            "dipdupModelUpdateAggregate": [
                239,
                {
                    "distinct_on": [
                        246,
                        "[dipdup_model_update_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        245,
                        "[dipdup_model_update_order_by!]"
                    ],
                    "where": [
                        242
                    ]
                }
            ],
            "dipdupModelUpdateByPk": [
                238,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "dipdupSchema": [
                254,
                {
                    "distinct_on": [
                        261,
                        "[dipdup_schema_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        260,
                        "[dipdup_schema_order_by!]"
                    ],
                    "where": [
                        257
                    ]
                }
            ],
            "dipdupSchemaAggregate": [
                255,
                {
                    "distinct_on": [
                        261,
                        "[dipdup_schema_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        260,
                        "[dipdup_schema_order_by!]"
                    ],
                    "where": [
                        257
                    ]
                }
            ],
            "dipdupSchemaByPk": [
                254,
                {
                    "name": [
                        5,
                        "String!"
                    ]
                }
            ],
            "dipdupTokenMetadata": [
                262,
                {
                    "distinct_on": [
                        270,
                        "[dipdup_token_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        269,
                        "[dipdup_token_metadata_order_by!]"
                    ],
                    "where": [
                        266
                    ]
                }
            ],
            "dipdupTokenMetadataAggregate": [
                263,
                {
                    "distinct_on": [
                        270,
                        "[dipdup_token_metadata_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        269,
                        "[dipdup_token_metadata_order_by!]"
                    ],
                    "where": [
                        266
                    ]
                }
            ],
            "dipdupTokenMetadataByPk": [
                262,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "diplomat": [
                278,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "diplomatAggregate": [
                279,
                {
                    "distinct_on": [
                        298,
                        "[diplomat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        297,
                        "[diplomat_order_by!]"
                    ],
                    "where": [
                        284
                    ]
                }
            ],
            "diplomatByPk": [
                278,
                {
                    "tokenId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "diplomatIpfs": [
                285,
                {
                    "distinct_on": [
                        292,
                        "[diplomat_ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        291,
                        "[diplomat_ipfs_order_by!]"
                    ],
                    "where": [
                        288
                    ]
                }
            ],
            "diplomatIpfsAggregate": [
                286,
                {
                    "distinct_on": [
                        292,
                        "[diplomat_ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        291,
                        "[diplomat_ipfs_order_by!]"
                    ],
                    "where": [
                        288
                    ]
                }
            ],
            "diplomatIpfsByPk": [
                285,
                {
                    "uri": [
                        5,
                        "String!"
                    ]
                }
            ],
            "directChallenge": [
                313,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "directChallengeAggregate": [
                314,
                {
                    "distinct_on": [
                        325,
                        "[direct_challenge_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        324,
                        "[direct_challenge_order_by!]"
                    ],
                    "where": [
                        319
                    ]
                }
            ],
            "directChallengeByPk": [
                313,
                {
                    "challengeId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "faucetNft": [
                340,
                {
                    "distinct_on": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        351,
                        "[faucet_nft_order_by!]"
                    ],
                    "where": [
                        346
                    ]
                }
            ],
            "faucetNftAggregate": [
                341,
                {
                    "distinct_on": [
                        352,
                        "[faucet_nft_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        351,
                        "[faucet_nft_order_by!]"
                    ],
                    "where": [
                        346
                    ]
                }
            ],
            "faucetNftByPk": [
                340,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "ipfs": [
                367,
                {
                    "distinct_on": [
                        375,
                        "[ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        374,
                        "[ipfs_order_by!]"
                    ],
                    "where": [
                        371
                    ]
                }
            ],
            "ipfsAggregate": [
                368,
                {
                    "distinct_on": [
                        375,
                        "[ipfs_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        374,
                        "[ipfs_order_by!]"
                    ],
                    "where": [
                        371
                    ]
                }
            ],
            "ipfsByPk": [
                367,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lockedResource": [
                386,
                {
                    "distinct_on": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        397,
                        "[locked_resource_order_by!]"
                    ],
                    "where": [
                        392
                    ]
                }
            ],
            "lockedResourceAggregate": [
                387,
                {
                    "distinct_on": [
                        398,
                        "[locked_resource_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        397,
                        "[locked_resource_order_by!]"
                    ],
                    "where": [
                        392
                    ]
                }
            ],
            "lockedResourceByPk": [
                386,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lockedToken": [
                413,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "lockedTokenAggregate": [
                414,
                {
                    "distinct_on": [
                        425,
                        "[locked_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        424,
                        "[locked_token_order_by!]"
                    ],
                    "where": [
                        419
                    ]
                }
            ],
            "lockedTokenByPk": [
                413,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lottery": [
                440,
                {
                    "distinct_on": [
                        475,
                        "[lottery_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        447,
                        "[lottery_order_by!]"
                    ],
                    "where": [
                        444
                    ]
                }
            ],
            "lotteryAggregate": [
                441,
                {
                    "distinct_on": [
                        475,
                        "[lottery_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        447,
                        "[lottery_order_by!]"
                    ],
                    "where": [
                        444
                    ]
                }
            ],
            "lotteryByPk": [
                440,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "lotteryParticipant": [
                448,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "lotteryParticipantAggregate": [
                449,
                {
                    "distinct_on": [
                        460,
                        "[lottery_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        459,
                        "[lottery_participant_order_by!]"
                    ],
                    "where": [
                        454
                    ]
                }
            ],
            "lotteryParticipantByPk": [
                448,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "marketplace": [
                483,
                {
                    "distinct_on": [
                        491,
                        "[marketplace_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        490,
                        "[marketplace_order_by!]"
                    ],
                    "where": [
                        487
                    ]
                }
            ],
            "marketplaceAggregate": [
                484,
                {
                    "distinct_on": [
                        491,
                        "[marketplace_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        490,
                        "[marketplace_order_by!]"
                    ],
                    "where": [
                        487
                    ]
                }
            ],
            "marketplaceByPk": [
                483,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "marketplaceStat": [
                492,
                {
                    "distinct_on": [
                        500,
                        "[marketplace_stat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        499,
                        "[marketplace_stat_order_by!]"
                    ],
                    "where": [
                        496
                    ]
                }
            ],
            "marketplaceStatAggregate": [
                493,
                {
                    "distinct_on": [
                        500,
                        "[marketplace_stat_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        499,
                        "[marketplace_stat_order_by!]"
                    ],
                    "where": [
                        496
                    ]
                }
            ],
            "marketplaceStatByPk": [
                492,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "operation": [
                517,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "operationAggregate": [
                518,
                {
                    "distinct_on": [
                        529,
                        "[operation_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        528,
                        "[operation_order_by!]"
                    ],
                    "where": [
                        523
                    ]
                }
            ],
            "operationByPk": [
                517,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "primarySale": [
                545,
                {
                    "distinct_on": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        572,
                        "[primary_sale_order_by!]"
                    ],
                    "where": [
                        551
                    ]
                }
            ],
            "primarySaleAggregate": [
                546,
                {
                    "distinct_on": [
                        573,
                        "[primary_sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        572,
                        "[primary_sale_order_by!]"
                    ],
                    "where": [
                        551
                    ]
                }
            ],
            "primarySaleByPk": [
                545,
                {
                    "listingId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "primarySaleCounter": [
                552,
                {
                    "distinct_on": [
                        560,
                        "[primary_sale_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        559,
                        "[primary_sale_counter_order_by!]"
                    ],
                    "where": [
                        556
                    ]
                }
            ],
            "primarySaleCounterAggregate": [
                553,
                {
                    "distinct_on": [
                        560,
                        "[primary_sale_counter_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        559,
                        "[primary_sale_counter_order_by!]"
                    ],
                    "where": [
                        556
                    ]
                }
            ],
            "primarySaleCounterByPk": [
                552,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "raffle": [
                588,
                {
                    "distinct_on": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        599,
                        "[raffle_order_by!]"
                    ],
                    "where": [
                        594
                    ]
                }
            ],
            "raffleAggregate": [
                589,
                {
                    "distinct_on": [
                        627,
                        "[raffle_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        599,
                        "[raffle_order_by!]"
                    ],
                    "where": [
                        594
                    ]
                }
            ],
            "raffleByPk": [
                588,
                {
                    "raffleId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "raffleParticipant": [
                600,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffleParticipantAggregate": [
                601,
                {
                    "distinct_on": [
                        612,
                        "[raffle_participant_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        611,
                        "[raffle_participant_order_by!]"
                    ],
                    "where": [
                        606
                    ]
                }
            ],
            "raffleParticipantByPk": [
                600,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "sale": [
                642,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "saleAggregate": [
                643,
                {
                    "distinct_on": [
                        654,
                        "[sale_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        653,
                        "[sale_order_by!]"
                    ],
                    "where": [
                        648
                    ]
                }
            ],
            "saleByPk": [
                642,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "search_artifact": [
                785,
                {
                    "args": [
                        669,
                        "search_artifact_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_artifact_aggregate": [
                786,
                {
                    "args": [
                        669,
                        "search_artifact_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_collectible": [
                785,
                {
                    "args": [
                        670,
                        "search_collectible_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_collectible_aggregate": [
                786,
                {
                    "args": [
                        670,
                        "search_collectible_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_tezotop": [
                785,
                {
                    "args": [
                        671,
                        "search_tezotop_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_tezotop_aggregate": [
                786,
                {
                    "args": [
                        671,
                        "search_tezotop_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_ticket": [
                785,
                {
                    "args": [
                        672,
                        "search_ticket_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_ticket_aggregate": [
                786,
                {
                    "args": [
                        672,
                        "search_ticket_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_token": [
                785,
                {
                    "args": [
                        673,
                        "search_token_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_token_aggregate": [
                786,
                {
                    "args": [
                        673,
                        "search_token_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_unit": [
                785,
                {
                    "args": [
                        674,
                        "search_unit_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "search_unit_aggregate": [
                786,
                {
                    "args": [
                        674,
                        "search_unit_args!"
                    ],
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "stockArtifact": [
                675,
                {
                    "distinct_on": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        686,
                        "[stock_artifact_order_by!]"
                    ],
                    "where": [
                        681
                    ]
                }
            ],
            "stockArtifactAggregate": [
                676,
                {
                    "distinct_on": [
                        687,
                        "[stock_artifact_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        686,
                        "[stock_artifact_order_by!]"
                    ],
                    "where": [
                        681
                    ]
                }
            ],
            "stockArtifactByPk": [
                675,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "tezotop": [
                702,
                {
                    "distinct_on": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        713,
                        "[tezotop_order_by!]"
                    ],
                    "where": [
                        708
                    ]
                }
            ],
            "tezotopAggregate": [
                703,
                {
                    "distinct_on": [
                        714,
                        "[tezotop_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        713,
                        "[tezotop_order_by!]"
                    ],
                    "where": [
                        708
                    ]
                }
            ],
            "tezotopByPk": [
                702,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "ticket": [
                729,
                {
                    "distinct_on": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        767,
                        "[ticket_order_by!]"
                    ],
                    "where": [
                        735
                    ]
                }
            ],
            "ticketAggregate": [
                730,
                {
                    "distinct_on": [
                        768,
                        "[ticket_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        767,
                        "[ticket_order_by!]"
                    ],
                    "where": [
                        735
                    ]
                }
            ],
            "ticketByPk": [
                729,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "ticketLedger": [
                736,
                {
                    "distinct_on": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        747,
                        "[ticket_ledger_order_by!]"
                    ],
                    "where": [
                        742
                    ]
                }
            ],
            "ticketLedgerAggregate": [
                737,
                {
                    "distinct_on": [
                        748,
                        "[ticket_ledger_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        747,
                        "[ticket_ledger_order_by!]"
                    ],
                    "where": [
                        742
                    ]
                }
            ],
            "ticketLedgerByPk": [
                736,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "token": [
                785,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokenAggregate": [
                786,
                {
                    "distinct_on": [
                        797,
                        "[token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        796,
                        "[token_order_by!]"
                    ],
                    "where": [
                        791
                    ]
                }
            ],
            "tokenByPk": [
                785,
                {
                    "tokenId": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "topBuyer": [
                812,
                {
                    "distinct_on": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        823,
                        "[top_buyer_order_by!]"
                    ],
                    "where": [
                        818
                    ]
                }
            ],
            "topBuyerAggregate": [
                813,
                {
                    "distinct_on": [
                        824,
                        "[top_buyer_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        823,
                        "[top_buyer_order_by!]"
                    ],
                    "where": [
                        818
                    ]
                }
            ],
            "topBuyerByPk": [
                812,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "unit": [
                839,
                {
                    "distinct_on": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        850,
                        "[unit_order_by!]"
                    ],
                    "where": [
                        845
                    ]
                }
            ],
            "unitAggregate": [
                840,
                {
                    "distinct_on": [
                        851,
                        "[unit_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        850,
                        "[unit_order_by!]"
                    ],
                    "where": [
                        845
                    ]
                }
            ],
            "unitByPk": [
                839,
                {
                    "uid": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "user": [
                866,
                {
                    "distinct_on": [
                        873,
                        "[user_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        872,
                        "[user_order_by!]"
                    ],
                    "where": [
                        869
                    ]
                }
            ],
            "userAggregate": [
                867,
                {
                    "distinct_on": [
                        873,
                        "[user_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        872,
                        "[user_order_by!]"
                    ],
                    "where": [
                        869
                    ]
                }
            ],
            "userByPk": [
                866,
                {
                    "address": [
                        5,
                        "String!"
                    ]
                }
            ],
            "whitelistedToken": [
                874,
                {
                    "distinct_on": [
                        882,
                        "[whitelisted_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        881,
                        "[whitelisted_token_order_by!]"
                    ],
                    "where": [
                        878
                    ]
                }
            ],
            "whitelistedTokenAggregate": [
                875,
                {
                    "distinct_on": [
                        882,
                        "[whitelisted_token_select_column!]"
                    ],
                    "limit": [
                        3
                    ],
                    "offset": [
                        3
                    ],
                    "order_by": [
                        881,
                        "[whitelisted_token_order_by!]"
                    ],
                    "where": [
                        878
                    ]
                }
            ],
            "whitelistedTokenByPk": [
                874,
                {
                    "id": [
                        3,
                        "Int!"
                    ]
                }
            ],
            "__typename": [
                5
            ]
        }
    }
}