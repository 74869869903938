import { useEffect, useState } from "react";
import { truncateAddress } from "../../utils/string";
import {
	clearActiveAccount,
	getActiveAccount,
	wallet,
} from "../../utils/tezos";
import Button from "../Button/Button";
import "./SyncWalletButton.scss";

export default function SyncWalletButton() {
	const [walletAddress, setWalletAddress] = useState("");
	useEffect(() => {
		wallet.client.getActiveAccount().then(account => {
			setWalletAddress(account?.address || "");
		});
	}, []);

	const [buttonHovered, setButtonHovered] = useState(false);
	return (
		<>
			{!walletAddress && (
				<Button
					className="sync-wallet-button"
					onClick={async () => {
						const account = await getActiveAccount();
						setWalletAddress(account.address);
					}}>
					Sync Wallet
				</Button>
			)}
			{!!walletAddress && (
				<Button
					className="sync-wallet-button"
					onClick={async () => {
						await clearActiveAccount();
						setWalletAddress("");
					}}
					onMouseEnter={() => setButtonHovered(true)}
					onMouseLeave={() => setButtonHovered(false)}>
					{buttonHovered ? "Disconnect" : truncateAddress(walletAddress)}
				</Button>
			)}
		</>
	);
}
