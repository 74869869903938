import { TransactionWalletOperation } from "@taquito/taquito";

/**
 * @param {*} op - Contarct Operation Object
 */
export async function _handleContractResponse(
	op: TransactionWalletOperation,
	onSuccess?: () => any | Promise<any>
) {
	const res = await op.confirmation(2);
	const status = await op.status();

	if (res.completed && status === "applied") {
		onSuccess?.();
		return;
	}

	throw new Error(`Transaction failed`);
}

export function parseContractCallErrorMessge(
	error: any
): {
	error: Error;
	code?: string;
} {
	console.log(error);
	let message = "Unkown error, try again.";
	let errorCode: string | undefined;

	switch (typeof error) {
		case "string":
			message = error;
			break;
		case "object":
			if (error.data) {
				const lastErrorObject = error.data[error.data.length - 1];
				if (lastErrorObject.with) {
					message =
						"Operation failed with error " + lastErrorObject.with.string;
					errorCode = lastErrorObject.with.string;
				} else {
					message = "Operation failed with error " + lastErrorObject.kind;
				}
			} else {
				message = error.description || error.message;
			}
			break;
		default:
			break;
	}

	return {
		error: new Error(message),
		code: errorCode,
	};
}
