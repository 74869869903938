import "./Resource.scss";
import { RESOURCES } from "../../config/resource";
import NavButtons from "../../components/NavButtons/NavButtons";
import { useNavigate } from "react-router-dom";
import { TOKEN_CATEGORIES } from "../../config/token";
import { FaucetNft } from "../../types/faucetNft";
import { useState } from "react";
import { TezotopAttribute } from "../../types/token";
import { mintNft } from "../../utils/mint";
export default function Resource({
	faucetNfts,
}: {
	faucetNfts: FaucetNft[] | null;
}) {
	const [resource, setResource] = useState({
		uno: 0,
		min: 0,
		mch: 0,
		enr: 0,
	});
	const navigate = useNavigate();
	return (
		<div className="resource">
			<h2> Enter resources to mint</h2>
			<div className="resource-table">
				{RESOURCES.map(r => (
					<div className="resource-element" key={r.name}>
						<img src={r.icon} alt={r.fullName} />
						<div className="value">
							<label htmlFor={r.name}>
								{r.fullName} ({r.name})
							</label>
							<input
								type="number"
								name={r.fullName}
								id={r.name}
								min="0"
								step="1"
								placeholder={`Enter ${r.name} to mint`}
								onChange={e => {
									//resource to mint are same as tezotop attributes
									const resourceName = r.name.toLowerCase() as TezotopAttribute;
									const value = Number(e.target.value);
									const newResource = { ...resource };
									newResource[resourceName] = value;
									setResource(newResource);
								}}
							/>
						</div>
					</div>
				))}
			</div>
			<NavButtons
				secondaryButtonProps={{
					onClick: () => {
						navigate(
							"/" + TOKEN_CATEGORIES[TOKEN_CATEGORIES.length - 1].tokenName
						);
					},
				}}
				primaryButtonProps={{
					onClick: () => {
						mintNft(faucetNfts, resource);
					},
					children: "Mint",
				}}
			/>
		</div>
	);
}
