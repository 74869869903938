import { ButtonProps } from "../../types/Button";
import Button from "../Button/Button";
import "./NavButtons.scss";
export default function NavButtons({
	primaryButtonProps,
	showSecondary = true,
	secondaryButtonProps,
}: {
	primaryButtonProps?: Partial<ButtonProps>;
	showSecondary?: boolean;
	secondaryButtonProps?: Partial<ButtonProps>;
}) {
	return (
		<div className="nav-buttons">
			{showSecondary && (
				<Button
					color="secondary"
					className="secondary"
					{...secondaryButtonProps}>
					{secondaryButtonProps?.children || "Back"}
				</Button>
			)}
			<Button className="primary" {...primaryButtonProps}>
				{primaryButtonProps?.children || "Next"}
			</Button>
		</div>
	);
}
