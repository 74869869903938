import { useEffect, useState, Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import "./App.scss";
import Header from "./components/Header/Header";
import { TOKEN_CATEGORIES } from "./config/token";
import Resource from "./pages/Resource/Resource";
import Tokens from "./pages/Tokens/Tokens";
import getFaucetNfts from "./services/api/faucetNft";
import { FaucetNft } from "./types/faucetNft";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	const [faucetNfts, setFaucetNfts] = useState<FaucetNft[] | null>(null);
	useEffect(() => {
		getFaucetNfts().then(nftData => setFaucetNfts(nftData));
	}, []);

	return (
		<div className="App">
			<Header />
			<Routes>
				{TOKEN_CATEGORIES.map(category => (
					<Fragment key={category.tokenName}>
						<Route
							path={`/${category.tokenName}`}
							element={
								<Tokens
									faucetNfts={faucetNfts}
									setFaucetNfts={setFaucetNfts}
									category={category.tokenName}
								/>
							}
						/>
					</Fragment>
				))}
				<Route
					path="/resource"
					element={<Resource faucetNfts={faucetNfts} />}
				/>
				<Route index element={<Navigate to="/tezotop" replace />} />
			</Routes>
			<ToastContainer position="bottom-right" />
		</div>
	);
}

export default App;
