import { MichelsonMap } from "@taquito/taquito";
import { char2Bytes } from "@taquito/utils";
import { toast } from "react-toastify";
import { ContractErrors } from "../types/contract";

import { FaucetNft } from "../types/faucetNft";
import { ResourceToMint } from "../types/Resource";
import { TokenCategory } from "../types/token";
import { getActiveAccount, getContract } from "./tezos";
import { parseContractCallErrorMessge, _handleContractResponse } from "./utils";

export function seperateIntStrings(obj: { [key: string]: string | number }) {
	const ints: { [key: string]: number } = {};
	const strings: { [key: string]: string } = {};
	Object.keys(obj).forEach(key => {
		if (typeof obj[key] === "number") {
			ints[key] = obj[key] as number;
		} else {
			strings[key] = obj[key] as string;
		}
	});
	return { ints, strings };
}
export function toMichelsonMap(obj: { [key: string]: any }) {
	const map = new MichelsonMap();

	Object.keys(obj).forEach(key => {
		map.set(key, obj[key]);
	});

	return map;
}

export async function mintNft(
	faucetNft: FaucetNft[] | null,
	resource: ResourceToMint
) {
	const resourceMap = toMichelsonMap({
		0: resource.uno * 1e9,
		1: resource.min * 1e9,
		2: resource.enr * 1e9,
		3: resource.mch * 1e9,
	});
	if (faucetNft === null) return;
	const nfts: any[] = [];
	console.log("to mint");
	faucetNft
		.filter(nft => nft.editions && nft.editions > 0)
		.forEach(nft => {
			console.log(nft.metadata?.name, nft.editions);
			const token = nft.metadata?.tokens?.[0];
			const { ints, strings } = seperateIntStrings(
				token?.[`${token?.category as TokenCategory}s`]?.[0] || ({} as any)
			);
			if ("wave" in ints) {
				ints.speed = ints.wave;
				delete ints.wave;
			}
			const toMint = {
				attributes: {
					category: token?.category,
					ints: toMichelsonMap(ints),
					sets: toMichelsonMap({}),
					strings: toMichelsonMap(strings),
				},
				editions: nft.editions,
				metadata: char2Bytes(nft.metadata?.uri as string),
			};
			nfts.push(toMint);
		});
	const _toastId = toast.loading("Confirm transaction on your wallet...");
	try {
		const [account, contract] = await Promise.all([
			getActiveAccount(),
			getContract(),
		]);
		const op = await contract.methods
			.claim(account.address, nfts, resourceMap)
			.send();
		toast.update(_toastId, { render: "Minting NFTs and Resources..." });
		_handleContractResponse(op, () => {
			toast.update(_toastId, {
				render: "Operation successful!",
				type: "success",
				isLoading: false,
			});
			console.log("minted");
		});
	} catch (e) {
		console.log(`Error in minting: ${e}`);
		const { error, code } = parseContractCallErrorMessge(e);
		if (code === ContractErrors.LIMIT_EXCEEDED) {
			toast.update(_toastId, {
				render: "Can not mint that much resources",
				type: "error",
				isLoading: false,
			});
			return;
		}
		toast.update(_toastId, {
			render: `${code || ""} ${error.message}`,
			type: "error",
			isLoading: false,
		});
		console.log(error);
		console.log({ code });
	}
}
