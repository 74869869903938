import {
	energyIcon,
	machineryIcon,
	mineralIcon,
	unoIcon,
} from "../assets/icons";

export const RESOURCES = [
					{
						name: "UNO",
						fullName: "Unobtanium",
						icon: unoIcon,
					},
					{
						name: "MIN",
						fullName: "Mineral",
						icon: mineralIcon,
					},
					{
						name: "ENR",
						fullName: "Energy",
						icon: energyIcon,
					},
					{
						name: "MCH",
						fullName: "Machinery",
						icon: machineryIcon,
					},
				];
