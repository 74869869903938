import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import NavButtons from "../../components/NavButtons/NavButtons";
import TokenCard from "../../components/TokenCard/TokenCard";
import { TOKEN_CATEGORIES } from "../../config/token";
import { TokensProps } from "../../types/token";
import "./Tokens.scss";
export default function Tokens({
	faucetNfts,
	setFaucetNfts,
	category,
}: TokensProps) {
	const navigate = useNavigate();
	const currPageIndex: number | null = TOKEN_CATEGORIES.findIndex(
		e => e.tokenName === category
	);
	let nextPage: string;
	let prevPage: string | null;
	if (currPageIndex + 1 >= TOKEN_CATEGORIES.length) nextPage = "/resource";
	else nextPage = "/" + TOKEN_CATEGORIES[currPageIndex + 1].tokenName;
	if (currPageIndex - 1 < 0) prevPage = null;
	else prevPage = "/" + TOKEN_CATEGORIES[currPageIndex - 1].tokenName;

	return (
		<div className="tokens">
			<h2>Select {category}s to Mint</h2>
			<div className="tokens-grid">
				{faucetNfts?.map(nft => {
					//few units having null attributes should be hidden
					const nullUnit =
						nft.metadata?.tokens?.[0]?.units?.[0]?.accuracy === null;
					const showCard =
						nft.metadata?.tokens?.[0]?.category === category && !nullUnit;
					const setEdition = (editions: number) => {
						nft.editions = editions;
						setFaucetNfts(faucetNfts);
					};
					return (
						showCard &&
						nft.metadata && (
							<Fragment key={nft.metadata.uri}>
								<TokenCard
									metadata={nft.metadata}
									editions={nft.editions || 0}
									setEdition={setEdition}
								/>
							</Fragment>
						)
					);
				})}
			</div>
			<NavButtons
				primaryButtonProps={{
					onClick: () => {
						navigate(nextPage);
					},
				}}
				showSecondary={!!prevPage}
				secondaryButtonProps={{
					onClick: () => {
						prevPage && navigate(prevPage);
					},
				}}
			/>
		</div>
	);
}
