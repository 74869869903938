import { Token, TokenCategory } from "../../types/token";
import "./TokenAttributes.scss";
export default function TokenAttributes({
	attributes,
	category,
}: {
	attributes: Token;
	category: TokenCategory;
}) {
	const collectibleAttributes = ["common", "rare", "epic", "mythical"];
	return (
		<div className="token-attributes">
			{Object.keys(attributes).map(attribute => {
				const _attribute = attribute as keyof Token;
				if (category === "collectible") {
					const rarity = collectibleAttributes[Number(attributes[_attribute])];
					return (
						<span key={rarity} className={rarity}>
							{rarity}
						</span>
					);
				}
				return (
					<span key={attribute}>{`${attribute}: ${attributes[_attribute]}${
						category === "unit" ? "" : "x"
					} `}</span>
				);
			})}
		</div>
	);
}
