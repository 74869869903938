import {
  linkTypeMap,
  createClient as createClientOriginal,
  generateGraphqlOperation,
  assertSameVersion,
} from '@genql/runtime'
import types from './types.esm'
var typeMap = linkTypeMap(types)
export * from './guards.esm'

export var version = '2.10.0'
assertSameVersion(version)

export var createClient = function(options) {
  options = options || {}
  var optionsCopy = {
    url: 'https://testnet-api.tezotopia.com/v1/graphql',
    queryRoot: typeMap.Query,
    mutationRoot: typeMap.Mutation,
    subscriptionRoot: typeMap.Subscription,
  }
  for (var name in options) {
    optionsCopy[name] = options[name]
  }
  return createClientOriginal(optionsCopy)
}

export const enumartifactSelectColumn = {
  enr: 'enr',
  mch: 'mch',
  min: 'min',
  name: 'name',
  stock: 'stock',
  tokenId: 'tokenId',
  uid: 'uid',
  uno: 'uno',
}

export const enumbattleCounterSelectColumn = {
  address: 'address',
  counter: 'counter',
}

export const enumbattleCrateSelectColumn = {
  address: 'address',
  amount: 'amount',
}

export const enumbattleSelectColumn = {
  active: 'active',
  battleType: 'battleType',
  counter: 'counter',
  id: 'id',
  lockTime: 'lockTime',
  unlockTime: 'unlockTime',
  userId: 'userId',
}

export const enumbattleUnitSelectColumn = {
  battleId: 'battleId',
  unitId: 'unitId',
}

export const enumbuildingSelectColumn = {
  active: 'active',
  boosted: 'boosted',
  buildTime: 'buildTime',
  id: 'id',
  symbol: 'symbol',
  tezotopId: 'tezotopId',
  tokenId: 'tokenId',
}

export const enumcollectibleSelectColumn = {
  level: 'level',
  name: 'name',
  tokenId: 'tokenId',
  uid: 'uid',
}

export const enumdipdupContractMetadataSelectColumn = {
  contract: 'contract',
  createdAt: 'createdAt',
  id: 'id',
  metadata: 'metadata',
  network: 'network',
  updateId: 'updateId',
  updatedAt: 'updatedAt',
}

export const enumdipdupContractSelectColumn = {
  address: 'address',
  createdAt: 'createdAt',
  name: 'name',
  typename: 'typename',
  updatedAt: 'updatedAt',
}

export const enumdipdupHeadSelectColumn = {
  createdAt: 'createdAt',
  hash: 'hash',
  level: 'level',
  name: 'name',
  timestamp: 'timestamp',
  updatedAt: 'updatedAt',
}

export const enumdipdupHeadStatusSelectColumn = {
  name: 'name',
  status: 'status',
}

export const enumdipdupIndexSelectColumn = {
  configHash: 'configHash',
  createdAt: 'createdAt',
  level: 'level',
  name: 'name',
  status: 'status',
  template: 'template',
  templateValues: 'templateValues',
  type: 'type',
  updatedAt: 'updatedAt',
}

export const enumdipdupModelUpdateSelectColumn = {
  action: 'action',
  createdAt: 'createdAt',
  data: 'data',
  id: 'id',
  index: 'index',
  level: 'level',
  modelName: 'modelName',
  modelPk: 'modelPk',
  updatedAt: 'updatedAt',
}

export const enumdipdupSchemaSelectColumn = {
  createdAt: 'createdAt',
  hash: 'hash',
  name: 'name',
  reindex: 'reindex',
  updatedAt: 'updatedAt',
}

export const enumdipdupTokenMetadataSelectColumn = {
  contract: 'contract',
  createdAt: 'createdAt',
  id: 'id',
  metadata: 'metadata',
  network: 'network',
  tokenId: 'tokenId',
  updateId: 'updateId',
  updatedAt: 'updatedAt',
}

export const enumdiplomatIpfsSelectColumn = {
  arm: 'arm',
  background: 'background',
  cloth: 'cloth',
  description: 'description',
  headdress: 'headdress',
  image: 'image',
  katana: 'katana',
  name: 'name',
  skin: 'skin',
  status: 'status',
  symbol: 'symbol',
  thumbnail: 'thumbnail',
  uri: 'uri',
}

export const enumdiplomatSelectColumn = {
  charisma: 'charisma',
  class_: 'class_',
  creatorId: 'creatorId',
  influence: 'influence',
  ipfsId: 'ipfsId',
  listTime: 'listTime',
  mintTime: 'mintTime',
  onSale: 'onSale',
  ownerId: 'ownerId',
  price: 'price',
  tokenId: 'tokenId',
}

export const enumdirectChallengeSelectColumn = {
  acceptorId: 'acceptorId',
  acceptorLockId: 'acceptorLockId',
  challengeId: 'challengeId',
  creatorId: 'creatorId',
  creatorLockId: 'creatorLockId',
  extra: 'extra',
  status: 'status',
  time: 'time',
  winnerId: 'winnerId',
}

export const enumfaucetNftSelectColumn = {
  id: 'id',
  metadataId: 'metadataId',
}

export const enumipfsSelectColumn = {
  creator: 'creator',
  description: 'description',
  id: 'id',
  image: 'image',
  name: 'name',
  symbol: 'symbol',
  thumbnail: 'thumbnail',
  uri: 'uri',
}

export const enumlockedResourceSelectColumn = {
  enr: 'enr',
  id: 'id',
  startTime: 'startTime',
  uno: 'uno',
  userId: 'userId',
}

export const enumlockedTokenSelectColumn = {
  amount: 'amount',
  battleLockId: 'battleLockId',
  id: 'id',
  tokenId: 'tokenId',
}

export const enumlotteryParticipantSelectColumn = {
  entries: 'entries',
  id: 'id',
  lotteryId: 'lotteryId',
  userId: 'userId',
  won: 'won',
}

export const enumlotterySelectColumn = {
  finished: 'finished',
  id: 'id',
  participantCount: 'participantCount',
  startTime: 'startTime',
  ticketCount: 'ticketCount',
}

export const enummarketplaceSelectColumn = {
  id: 'id',
  onSale: 'onSale',
  price: 'price',
  time: 'time',
  tokenId: 'tokenId',
}

export const enummarketplaceStatSelectColumn = {
  id: 'id',
  lastUpdatedAt: 'lastUpdatedAt',
  name: 'name',
  value: 'value',
}

export const enumoperationSelectColumn = {
  event: 'event',
  fromUserId: 'fromUserId',
  id: 'id',
  itemId: 'itemId',
  time: 'time',
  toUserId: 'toUserId',
  tokenId: 'tokenId',
  value: 'value',
}

export const enumorderBy = {
  asc: 'asc',
  asc_nulls_first: 'asc_nulls_first',
  asc_nulls_last: 'asc_nulls_last',
  desc: 'desc',
  desc_nulls_first: 'desc_nulls_first',
  desc_nulls_last: 'desc_nulls_last',
}

export const enumprimarySaleCounterSelectColumn = {
  address: 'address',
  counter: 'counter',
}

export const enumprimarySaleSelectColumn = {
  left: 'left',
  listingId: 'listingId',
  listingTime: 'listingTime',
  price: 'price',
  tokenCount: 'tokenCount',
  tokenId: 'tokenId',
}

export const enumraffleParticipantSelectColumn = {
  claimed: 'claimed',
  id: 'id',
  raffleId: 'raffleId',
  tokenId: 'tokenId',
  userId: 'userId',
  won: 'won',
}

export const enumraffleSelectColumn = {
  endTime: 'endTime',
  finished: 'finished',
  participantCount: 'participantCount',
  price: 'price',
  raffleId: 'raffleId',
  startTime: 'startTime',
  tokenCount: 'tokenCount',
  tokenId: 'tokenId',
}

export const enumsaleSelectColumn = {
  amount: 'amount',
  buyerId: 'buyerId',
  id: 'id',
  sellerId: 'sellerId',
  time: 'time',
  tokenId: 'tokenId',
}

export const enumstockArtifactSelectColumn = {
  enr: 'enr',
  id: 'id',
  ipfsId: 'ipfsId',
  mch: 'mch',
  min: 'min',
  priceEnr: 'priceEnr',
  priceMch: 'priceMch',
  priceMin: 'priceMin',
  priceUno: 'priceUno',
  priceXtz: 'priceXtz',
  uno: 'uno',
}

export const enumtezotopSelectColumn = {
  coordinateX: 'coordinateX',
  coordinateY: 'coordinateY',
  coordinateZ: 'coordinateZ',
  enr: 'enr',
  lastClaimTime: 'lastClaimTime',
  mch: 'mch',
  min: 'min',
  name: 'name',
  tezotopian: 'tezotopian',
  tokenId: 'tokenId',
  uid: 'uid',
  uno: 'uno',
}

export const enumticketLedgerSelectColumn = {
  id: 'id',
  ticketCount: 'ticketCount',
  userId: 'userId',
}

export const enumticketSelectColumn = {
  name: 'name',
  tokenId: 'tokenId',
  uid: 'uid',
}

export const enumtokenSelectColumn = {
  category: 'category',
  creatorId: 'creatorId',
  ipfsId: 'ipfsId',
  marketplaceId: 'marketplaceId',
  mintTime: 'mintTime',
  name: 'name',
  ownerId: 'ownerId',
  tokenId: 'tokenId',
  uid: 'uid',
}

export const enumtopBuyerSelectColumn = {
  amount: 'amount',
  id: 'id',
  userId: 'userId',
}

export const enumunitSelectColumn = {
  accuracy: 'accuracy',
  attack: 'attack',
  damage: 'damage',
  damageTime: 'damageTime',
  defence: 'defence',
  exp: 'exp',
  hp: 'hp',
  name: 'name',
  plays: 'plays',
  stealth: 'stealth',
  tech: 'tech',
  tokenId: 'tokenId',
  type: 'type',
  uid: 'uid',
  wave: 'wave',
}

export const enumuserSelectColumn = {
  address: 'address',
  username: 'username',
}

export const enumwhitelistedTokenSelectColumn = {
  contractAddress: 'contractAddress',
  contractId: 'contractId',
  decimals: 'decimals',
  id: 'id',
  name: 'name',
  symbol: 'symbol',
  thumbnail: 'thumbnail',
  tokenId: 'tokenId',
}

export var generateQueryOp = function(fields) {
  return generateGraphqlOperation('query', typeMap.Query, fields)
}
export var generateMutationOp = function(fields) {
  return generateGraphqlOperation('mutation', typeMap.Mutation, fields)
}
export var generateSubscriptionOp = function(fields) {
  return generateGraphqlOperation('subscription', typeMap.Subscription, fields)
}
export var everything = {
  __scalar: true,
}
