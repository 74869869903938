import { ProgressiveImageProps } from "../../types/ProgressiveImage";
import { useEffect, useState } from "react";
import { imgLoaderGif } from "../../assets/images";
import "./ProgressiveImage.scss";

export default function ProgressiveImage({
	src,
	placeholder = imgLoaderGif,
	alt,
	props,
}: ProgressiveImageProps) {
	const [image, setImage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	// EFFECTS
	useEffect(() => {
		const image = new Image();
		image.src = src || "";
		image.onload = () => {
			setImage(src || "");
			setIsLoading(false);
		};
	}, [src]);

	// RENDER
	return (
		<img
			className="ProgressiveImage"
			src={isLoading ? placeholder : image}
			alt={alt || "Progressive Image"}
			{...props}
		/>
	);
}
