import ProgressiveImage from "../ProgressiveImage/ProgressiveImage";
import { getImageUrl } from "../../utils/image";

import "./TokenCard.scss";
import { FaucetNftData } from "../../types/faucetNft";
import { TokenCategory } from "../../types/token";
import TokenAttributes from "../TokenAttributes/TokenAttributes";
import Edition from "../Edition/Edition";

export default function TokenCard({
									metadata,
									editions,
									setEdition,
								}: {
									metadata: FaucetNftData;
									editions: number;
									setEdition: (editions: number) => void;
								}) {
									const category = metadata.tokens?.[0]
										?.category as TokenCategory;
									const attributes =
										metadata.tokens?.[0]?.[`${category}s`]?.[0];
									// RENDER
									return (
										<div className="token-card">
											<div className={`pic pic-${category}`}>
												<ProgressiveImage
													src={
														metadata?.uri?.startsWith("data:image/png;base64")
															? metadata?.uri
															: getImageUrl(metadata.thumbnail)
													}
												/>
											</div>
											<div className="body">
												<div className="title">{metadata.name}</div>
											</div>
											{attributes && (
												<TokenAttributes
													attributes={attributes}
													category={category}
												/>
											)}
											<Edition editions={editions} setEdition={setEdition} />
										</div>
									);
								}
