import { useState } from "react";
import Button from "../Button/Button";
import "./Edition.scss";
export default function Edition({
	editions,
	setEdition,
}: {
	editions: number;
	setEdition: (edition: number) => void;
}) {
	const [_editions, _setEditions] = useState(editions);
	//change local state _editions : used to render component
	//and global state which is used while minting
	const changeEdition = (edition: number) => {
		setEdition(edition);
		_setEditions(edition);
	};
	return (
		<div className="edition">
			<>
				<span>Editions</span>{" "}
				<Button
					size="compact"
					onClick={() => {
						if (_editions < 1) return;
						changeEdition(_editions - 1);
					}}>
					-
				</Button>
				<input
					type="number"
					name="edition"
					value={_editions}
					onChange={e => changeEdition(parseInt(e.target.value))}
				/>
				<Button
					size="compact"
					onClick={() => {
						changeEdition(_editions + 1);
					}}>
					+
				</Button>
			</>
		</div>
	);
}
