import { tezotopiaLogoImage } from "../../assets/images";
import "./Header.scss";
import SyncWalletButton from "../SyncWalletButton/SyncWalletButton";
import { BiLinkExternal } from "react-icons/bi";

export default function Header() {
	return (
		<header className="header row">
			<a href="http://testnet.tezotopia.com/" target="_blank" rel="noreferrer">
				<img src={tezotopiaLogoImage} alt="logo" />
			</a>

			<div className="right">
				<a
					href="https://t.me/tezos_faucet_bot"
					target="_blank"
					rel="noopener noreferrer">
					Get Testnet XTZ <BiLinkExternal />
				</a>
				<SyncWalletButton />
			</div>
		</header>
	);
}
