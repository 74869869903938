import { BeaconWallet } from "@taquito/beacon-wallet";
import { AccountInfo, ColorMode, NetworkType } from "@airgap/beacon-sdk";
import { TezosToolkit } from "@taquito/taquito";

const DAPP_NAME = "Tezotpia Faucet Bot";
const CONTRACT_ADDRESS = "KT18oNE2K2Un5DmLM5dy7mJEL5hwpYdsPTpJ";
const wallet = new BeaconWallet({
	name: DAPP_NAME,
	preferredNetwork: "ghostnet" as NetworkType,
	colorMode: ColorMode.LIGHT,
});

// Setting the wallet as the wallet provider for Taquito.

const network = {
	type: "ghostnet" as NetworkType,
	rpc: "https://ghostnet.ecadinfra.com/",
};

const Tezos = new TezosToolkit(network.rpc);
Tezos.setWalletProvider(wallet);

const getContract = async () => {
	return Tezos.wallet.at(CONTRACT_ADDRESS);
};
const getActiveAccount = async (): Promise<AccountInfo> => {
	const activeAccount = await wallet.client.getActiveAccount();

	// no active account, we need permissions first
	if (!activeAccount) {
		await wallet.requestPermissions({ network });
		return getActiveAccount();
	}
	return activeAccount;
};

const clearActiveAccount = async () => {
	return wallet.client.clearActiveAccount();
};

export { wallet, getActiveAccount, clearActiveAccount, getContract, Tezos };
