import { ButtonProps } from '../../types/Button';
import React, { useState } from 'react'
import './Button.scss'

export default function Button({
  onClick,
  disabled,
  active,
  children,
  size = 'normal',
  color = 'primary',
  variant = 'rounded',
  startElement,
  endElement,
  type = 'button',
  className = '',
  onMouseEnter,
  onMouseOut,
  onMouseLeave,
  ...rest
}: ButtonProps) {
  const [loading, setLoading] = useState(false)

  async function onButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    setLoading(true)
    await onClick?.(e)

    try {
      setLoading(false)
    } catch (error) {
      // to catch error caused due to component unmount
      // no need to show error or throw it
    }
  }

  // RENDER
  return (
    <button
      className={[
        'button',
        variant,
        size,
        color,
        active ? 'active' : null,
        className,
        loading ? 'loading' : null,
      ].join(' ')}
      onClick={onButtonClick}
      disabled={disabled || loading}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onMouseLeave={onMouseLeave}
      type={type}
      {...rest}
    >
      {startElement && <span>{startElement}</span>}
      <span>{children}</span>
      {endElement && <span>{endElement}</span>}
    </button>
  )
}
